import { useEffect } from "react";
import { endpoints } from "../store/directories/directories.api";
import { useParams } from "react-router-dom";
import { getItemCreateOrder, updateItemCreateOrder } from "../store/order/selectors";
import { createOrderState } from "../store/order/createOrder.slice";
import { CUSTOM_SERVICE_SUB_TYPE, SERVICE_SUB_TYPE_IDS, SERVICE_SUB_TYPE_NAME, SERVICE_SUB_TYPE_SLUG, SERVICE_TYPE_ID, SERVICE_TYPE_NAME, SERVICE_TYPE_SLUG } from "../store/order/constant";
import { useActions } from "./actions";

export const useGetUrlSubtypes = () => {
  const { subtype: pathSubtype } = useParams();
  const activeType = getItemCreateOrder(SERVICE_TYPE_ID);
  const subtypes = getItemCreateOrder(SERVICE_SUB_TYPE_IDS);
  const custom = getItemCreateOrder(CUSTOM_SERVICE_SUB_TYPE);
  const activeSubTypes = subtypes?.length ? subtypes : custom || "";
  const { updateCreateOrderField } = useActions();
  const [getServiceSubTypes, { data: serviceSubTypes }] = endpoints.getServiceSubTypes.useLazyQuery();

  const updateField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
    updateItemCreateOrder(
        { 
            name,
            value
        },
        updateCreateOrderField
    );
  };

  useEffect(() => {
    if (activeType) {
        getServiceSubTypes({ id: activeType });
    }
  }, [activeType]);

  useEffect(() => {
    if (!serviceSubTypes?.length) return;

    if (activeSubTypes?.length > 1) {
        return;
    }

    const slugSubtype = pathSubtype?.replace(/^subtype-/gi, '') || '';
    const selectedSubtype= serviceSubTypes?.find(item => item.slug === slugSubtype || item.id === activeSubTypes[0]);

    if (selectedSubtype?.slug && (pathSubtype !== `subtype-${selectedSubtype.slug}`)) {
        updateField<typeof SERVICE_SUB_TYPE_SLUG>(SERVICE_SUB_TYPE_SLUG, selectedSubtype.slug ? `subtype-${selectedSubtype.slug}` : 'subtype');
        // history.pushState(null, "", `subtype-${selectedSubtype.slug}`);
    }

    if (selectedSubtype?.id && !activeSubTypes[0]) {
        updateField<typeof SERVICE_SUB_TYPE_IDS>(SERVICE_SUB_TYPE_IDS, [selectedSubtype.id]);
    }

    updateField<typeof SERVICE_SUB_TYPE_NAME>(SERVICE_SUB_TYPE_NAME, selectedSubtype?.name || null);
  }, [serviceSubTypes])

  return serviceSubTypes;
}

