import React, {useEffect, useMemo, useState} from "react";
import Form from "../Form/Form";
import {Category} from "../../models/directoryModel";
import {endpoints} from "../../store/directories/directories.api";
import {map} from "lodash";
import {TranslationObject} from "../../models/translationModal";
import {useSetSpecialistServiceCategoryMutation} from "../../store/user/user.api";
import {ChangeStepType} from "../../pages/Registration/SpecialistNewService/SpecialistNewService";
import {useHistoryBackHandler} from "../../hooks/useHistoryBackHandler";
import cn from "classnames";

interface SpecialistServicesProps {
    entities?: TranslationObject;
    changeStep: ChangeStepType;
    eventClearPreService?: () => unknown;
    setServicePayload?: React.Dispatch<React.SetStateAction<any>>;
    categoryId?: number | string;
    categoryName?: string;
}

export const SpecialistServices: React.FC<SpecialistServicesProps> = (
    props
) => {
    const {entities, setServicePayload, changeStep, categoryId, categoryName, eventClearPreService} = props || {};
    const [activeCategory, setActiveCategory] = useState<number | string | null>(categoryId ? categoryId : null);
    const [nameCategory, setNameCategory] = useState(categoryName || '');
    const {data: serviceCategories} =
        endpoints.getServiceCategories.useQuery();
    const [items, setItems] = useState<Category[]>([]);
    const [foundCategories, setFoundCategories] = useState<Category[]>([]);

    const [
        setSpecialistServiceCategory,
        {isSuccess, isLoading, data: setSpecialistServiceCategoryResponse},
    ] = useSetSpecialistServiceCategoryMutation();

    useEffect(() => {
        const items = (map(serviceCategories) || [])

        setItems(items);
        setFoundCategories(items);
    }, [serviceCategories]);

    const currentCategory = useMemo(() => {
        const category = items?.find(
            (category: Category) => category.id === activeCategory
        );

        const categoryName = category?.name;

        if (!categoryName) return;

        return categoryName;
    }, [activeCategory]);

    const isSubmitActive = !!((activeCategory) && (currentCategory || nameCategory) && !isLoading);

    useHistoryBackHandler(() => changeStep("category", true));

    useEffect(() => {
        const payload = setSpecialistServiceCategoryResponse?.payload;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (isSuccess && payload?.id) {
            setServicePayload?.(payload);
            changeStep("type");
        }
    }, [isSuccess, setSpecialistServiceCategoryResponse]);

    useEffect(() => {
        if (categoryId) {
            setActiveCategory(categoryId);
        }
    }, [categoryId]);

    useEffect(() => {
        if (nameCategory) {
            setNameCategory(nameCategory);
        }
    }, [nameCategory])

    const handleClick = (id: number | string) => {
        if (activeCategory === id) {
            setActiveCategory(null);
            setNameCategory('');
            return;
        }

        // Event clear pre service
        if (eventClearPreService) {
            eventClearPreService();
        }

        setActiveCategory(id);
    };


    const onSubmitForm = (
        event: React.FormEvent<HTMLFormElement>,
        activeCategory: number | null,
        isSubmitActive: boolean
    ) => {
        event.preventDefault();

        if (!isSubmitActive) return;

        const requestData = {
            body: {
                service_category_id: activeCategory,
            },
        };

        setSpecialistServiceCategory(requestData);
    };

    return (
        <>
            <h1 className="visually-hidden">
                {entities?.specialist_details_choose_category_services?.value ||
                    ""}
            </h1>
            <div className="inner-container">
                <div className="new-order__wrapper new-order__wrapper--first">
                    <div className="new-order__header">
                        <h2 className="title title--f46">
                            {entities
                                ?.specialist_details_choose_category_services
                                ?.value || ""}
                        </h2>
                        <p className="subtitle">
                            {entities
                                ?.specialist_details_select_one_service_category
                                ?.value || ""}
                        </p>
                    </div>
                    <div className="specialist new-order__form form">
                        <Form
                            onSubmit={(
                                event: React.FormEvent<HTMLFormElement>
                            ) =>
                                onSubmitForm(
                                    event,
                                    activeCategory,
                                    isSubmitActive
                                )
                            }
                            entities={entities}
                        >
                            {(invalidFields, error) => {
                                return (
                                    <>
                                        <div className="form__row">
                                            {foundCategories.map((elem: Category) => (
                                                <button
                                                    key={elem?.id}
                                                    className={cn(
                                                        "new-order-start__toggle",
                                                        activeCategory === elem.id && 'is-active'
                                                    )}
                                                    type="submit"
                                                    onClick={() =>
                                                        handleClick(elem?.id)
                                                    }
                                                >
                                                    {elem?.name}
                                                </button>
                                            ))}
                                        </div>
                                        <div className="form__row form__controls">
                                            <button
                                                onClick={() =>
                                                    changeStep("category", true)
                                                }
                                                className="btn form__back-link btn--transparent"
                                                type="button"
                                            >
                                                {entities?.common_back_button
                                                    ?.value || ""}
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};
