import React, {useEffect, useState} from "react";
import PageLoaders from "../../components/Loaders/PageLoaders";
import { BlocksBaseObject } from "../../models/homePageModel";
import Heading from "../../components/Heading";
import { ComponentProps } from "../../models/models";
import FeedBackForm from "../../components/FeedBackForm";

import pictureAdvertisersMobilePng from "../../img/content/poster/image9-mobile.png";
import pictureAdvertisersMobilePng2x from "../../img/content/poster/image9-mobile-2x.png";
import pictureAdvertisersMobileWebp from "../../img/content/poster/image9-mobile.webp";
import pictureAdvertisersMobileWebp2x from "../../img/content/poster/image9-mobile-2x.webp";
import pictureAdvertisersTabletPng from "../../img/content/poster/image9-tablet.png";
import pictureAdvertisersTabletPng2x from "../../img/content/poster/image9-tablet-2x.png";
import pictureAdvertisersTabletWebp from "../../img/content/poster/image9-tablet.webp";
import pictureAdvertisersTabletWebp2x from "../../img/content/poster/image9-tablet-2x.webp";
import pictureAdvertisersDesktopPng from "../../img/content/poster/image9-desktop.png";
import pictureAdvertisersDesktopPng2x from "../../img/content/poster/image9-desktop-2x.png";
import pictureAdvertisersDesktopWebp from "../../img/content/poster/image9-desktop.webp";
import pictureAdvertisersDesktopWebp2x from "../../img/content/poster/image9-desktop-2x.webp";

import pictureAdvertisersMobileBg from "../../img/content/poster/bg9-mobile.svg";
import pictureAdvertisersTabletBg from "../../img/content/poster/bg9-tablet.svg";
import pictureAdvertisersDesktopBg from "../../img/content/poster/bg9-desktop.svg";
import {Link} from "react-router-dom";

const Advertisers: React.FC<ComponentProps> = ({
    currentLanguage,
    entities,
    profile,
    isSpecialistOnUrl
}) => {
    const [blocks, setBlocks] = useState<BlocksBaseObject | null>(null);
    const table = blocks?.additional_block_table?.value;
    const tableKeys = table && Object.keys(table[0]);
    const isSpecialist = profile?.user_role === "specialist" || isSpecialistOnUrl;

    const advertisersPicture = {
        picture: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/webp',
                    srcSet: pictureAdvertisersDesktopWebp + ', ' + pictureAdvertisersDesktopWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 1024px)',
                    type: 'image/png',
                    srcSet: pictureAdvertisersDesktopPng + ', ' + pictureAdvertisersDesktopPng2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/webp',
                    srcSet: pictureAdvertisersTabletWebp + ', ' + pictureAdvertisersTabletWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/png',
                    srcSet: pictureAdvertisersTabletPng + ', ' + pictureAdvertisersTabletPng2x + ' 2x'
                },
                {
                    type: 'image/webp',
                    srcSet: pictureAdvertisersMobileWebp + ', ' + pictureAdvertisersMobileWebp2x + ' 2x'
                }
            ],
            src: pictureAdvertisersMobilePng,
            srcSet: pictureAdvertisersMobilePng2x + ' 2x'
        }
    }

    const advertisersBg = {
        bg: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/svg+xml',
                    srcSet: pictureAdvertisersDesktopBg
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/svg+xml',
                    srcSet: pictureAdvertisersTabletBg
                },
            ],
            src: pictureAdvertisersMobileBg
        }
    }

    const [adBlocks, setAdBlocks] = useState<any>([])
    const [error, setError] = useState<string>('')

    useEffect(() => {
        const getAds= async () => {
            try {
                const response = await fetch('/api/v1/advertising_blocks', { headers: { "X-Client-Language": currentLanguage }})
                const result = await response.json();

                if (!response.ok) throw new Error(result.description || 'Unhandled error');

                setAdBlocks(result.payload);

            } catch (e) {
                if (e instanceof Error) {
                    setError(e.message);
                } else {
                    setError('Unhandled error');
                }
            }
        }

        getAds()
    }, [currentLanguage])

    return (
        <PageLoaders
            type="custom_pages"
            code="advertisers"
            isCustomPage
            updateData={setBlocks}
            currentLanguage={currentLanguage}
        >
            <main>
                <div className="page offer-page">
                    <div className="page__container container">
                        <Heading
                            className="promo page__promo promo-page"
                            img={blocks?.main_block_image?.media}
                            title={blocks?.main_block_title?.value}
                            text={blocks?.main_block_description?.value}
                            isSpecialist={isSpecialist}
                            picture={advertisersPicture.picture}
                            bg={advertisersBg.bg}
                        />
                        <div className="page__wrapper">
                            {adBlocks.map(ad => (
                                <section className={`section section--flex prices ${!ad.image_position ? 'left' : ''}`}>
                                    <div className="section__column content-wrapper prices__info">
                                        <h2>{ad.title}</h2>
                                        <p>{ad.description}</p>
                                        <div className="table">
                                            <table>
                                                <tbody>
                                                {ad.prices?.map(
                                                    (
                                                        row,
                                                        key: number
                                                    ) => (
                                                        <tr key={key}>
                                                            <td>{row.title}</td>
                                                            <td>{row.amount}</td>
                                                        </tr>
                                                    )
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                        {ad.link_name && ad.link_url && (
                                            <Link to={ad.link_url}>{ad.link_name}</Link>
                                        )}
                                    </div>
                                    <div className={`section__column prices__img`}>
                                        <picture>
                                            <img
                                                src={ad.photo.original_url}
                                                width="668"
                                                height="690"
                                                alt="Additional block image"
                                            />
                                        </picture>
                                    </div>
                                </section>
                            ))}
                            <section className="section section--flex feedback-form">
                                <div className="section__column">
                                    <h2 className="title title--h2">
                                        {blocks?.feedback_title?.value}
                                    </h2>
                                    <div
                                        className="feedback-form__text"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                blocks?.feedback_text?.value ||
                                                ""
                                        }}
                                    />
                                </div>
                                <div className="section__column">
                                    {entities && currentLanguage && (
                                        <FeedBackForm
                                            profile={profile}
                                            entities={entities}
                                            currentLanguage={currentLanguage}
                                            type="advertisers"
                                        />
                                    )}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
        </PageLoaders>
    );
};

export default Advertisers;
