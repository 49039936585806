/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import { Link } from "react-router-dom";
import { TranslationObject } from "../../models/translationModal";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { useUserRole } from "../../hooks/useUserRole";

interface Props {
    entities?: TranslationObject;
    crumbs: any;
    isSpecialist?: boolean;
}

const Breadcrumbs: React.FC<Props> = ({
    entities,
    crumbs,
    isSpecialist = false
}) => {
    const currentLanguage = getCurrentLanguage();
    const { role } = useUserRole(isSpecialist ? "specialist" : "customer");
    return (
        <ul className="breadcrumbs">
            {crumbs?.map((elem, key) => (
                <li
                    key={key + 1}
                    className="breadcrumbs__item"
                >
                    { !elem.active && (
                        <Link className="breadcrumbs__link" to={`/${currentLanguage}/${elem.link}`} state={window.location.pathname}>
                            {elem?.name}
                        </Link>
                        
                    )}
                    { elem.active && (
                        <span className="breadcrumbs__link breadcrumbs__link--active">
                            {elem?.name}
                        </span>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default Breadcrumbs;
