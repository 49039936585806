import React from "react";
import classNames from "classnames";
import { Medium } from "../../models/homePageCustomer";
// import Search from "../../components/Search";

import backgroundGradient from "../../img/icons/background-gradient.svg";
// import backgroundGradientGreen from "../../img/icons/background-gradient-green.svg";
import backgroundGradientGreen from "../../img/content/main-page/promo-specialists-gradient.svg";
import backgroundGradientMobile from "../../img/content/main-page/background-gradient-mob.svg";

const Heading: React.FC<{
    className?: string;
    img?: any;
    title?: string | any;
    text?: string;
    text2?: string;
    subtitle?: string;
    buttons?: React.ReactNode;
    searchButton?: React.ReactNode;
    background?: string;
    titleType?: string;
    isSpecialist?: boolean;
    picture?: any;
    bg?: any;
    search?: React.ReactNode;
}> = ({
          className,
          img,
          title,
          text,
          subtitle,
          buttons,
          searchButton,
          background,
          titleType,
          isSpecialist = false,
          picture,
          bg,
          search
      }) => {
    const titleIsText = (titleType && title && titleType === "text") || false;
    const parseImage = (!!img && typeof img === 'string') ? img : (!!img && Array.isArray(img) && img.length) ? img[0].original_url : ''

    return (
        <section
            className={classNames("promo", {
                [className || ""]: !!className
            })}
        >
            <div className="promo__content-wrapper">
                <div className="promo__content-text">
                    {titleIsText ? (
                        <div
                            className="title title--h1"
                            dangerouslySetInnerHTML={{
                                __html: title || ""
                            }}
                        />
                    ) : (
                        <h1 className="title title--h1">{title}</h1>
                    )}

                    <div
                        className="promo__subtitle"
                        dangerouslySetInnerHTML={{ __html: subtitle || "" }}
                    />

                    <div
                        className="promo__text"
                        dangerouslySetInnerHTML={{ __html: text || "" }}
                    />

                    {search && (search)}
                    {searchButton && (
                        <div className="search" style={{ marginBottom: '16px' }}>{searchButton}</div>
                    )}
                    <div className="promo__order-buttons">{buttons}</div>
                </div>
                {bg && (
                    <div className="promo__bg">
                        <picture className="promo__picture-bg">
                            {bg.srcsets?.map((item, key) => (
                                <source
                                    key={key}
                                    media={item.media}
                                    type={item.type}
                                    srcSet={item.srcSet}
                                />
                            ))}
                            <img
                                src={bg.src}
                                width=""
                                height=""
                                alt="Background"
                            />
                        </picture>
                    </div>
                )}

                {!!img &&
                    <div className="promo__background-image-wrapper">
                        <picture className="promo__picture">
                            <img
                                src={parseImage}
                                alt="Promo picture"
                                width="600"
                                height="600"
                            />
                        </picture>
                    </div>
                }
            </div>
        </section>
    );
};

export default Heading;
