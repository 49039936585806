import {useNavigate, useSearchParams} from "react-router-dom";
import NotFoundPage from "../NotFound";
import React, {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import {convertDateToDDMMYYYY} from "../../untils";
import {useNotification} from "../../hooks/useNotification";
import {ComponentProps} from "../../models/models";

const Actions = ({entities, currentLanguage}: ComponentProps) => {
    const [params] = useSearchParams();
    const hash = params.get('hash');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<any>();
    const {setNotification} = useNotification();
    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const response = await fetch(`/api/v1/discount-companies/coupons/${hash}`, {method: 'GET', headers: { "X-Client-Language": currentLanguage}});
            if (!response.ok) throw new Error("Not found");

            const result = await response.json();
            if (!result) throw new Error("Empty data")

            setData(result.payload);

        } catch (error) {
            setError(true);
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        fetchData();
    }, [currentLanguage])


    if (!params.size || !hash) return <NotFoundPage/>
    if (isLoading) return <Loader/>
    if (error || !data) return <NotFoundPage/>


    const startDate = convertDateToDDMMYYYY(data.promotion.start_date.replace(" ", "T"));
    const endDate = convertDateToDDMMYYYY(data.promotion.end_date.replace(" ", "T"));


    const handleClickActivate = async () => {

        try {
            const response = await fetch('/api/v1/discount-companies/coupons/mark-as-used',
                {
                    method: "PUT",
                    body: new URLSearchParams({hash: hash}),
                    headers: {
                        "X-Client-Language": currentLanguage
                    }
                }
            );
            const result = await response.json()

            if (!response.ok) {
                throw new Error(result.description || 'Error');
            }

            setNotification({type: "confirm", title: "Success", description: result.payload})

            navigate('/')

        } catch (error) {
            setNotification({type: "warn", title: "Failure", description: error?.message})
        }

    }

    return (
        <div className="promotion-container">
            <div className="promotion-card">
                <div className="company-section">
                    <div className="company-info">
                        <h2 className="company-name">{data.promotion.company_campaign.name}</h2>
                        <p className="company-description">
                            {data.promotion.company_campaign.description}
                        </p>
                    </div>
                    {data.promotion.company_campaign.photo.original_url && (
                        <img
                            className="company-avatar"
                            src={data.promotion.company_campaign.photo.original_url}
                            alt="company avatar"
                        />
                    )}
                </div>
                <div className="promotion-section">
                    {data.promotion.photo.original_url && (
                        <img
                            className="promotion-avatar"
                            src={data.promotion.photo.original_url}
                            alt="promotion avatar"
                        />
                    )}
                    <div className="promotion-info">
                        <h3 className="promotion-title">{data.promotion.title}</h3>
                        <p>{startDate} - {endDate}</p>
                        <p className="promotion-description">
                            {data.promotion.description}
                        </p>
                    </div>
                </div>
                {(!data.is_expired && data.is_active && data.promotion.is_active) && (
                    <button
                        className="btn promo__order-button promo__order-button--green"
                        type="button"
                        onClick={handleClickActivate}

                    >
                        {entities?.promo_activate?.value || 'Activate'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default Actions;
