import React, { ReactNode, useState } from "react";
import Modal from "../../Modal";
import { TranslationObject } from "../../../models/translationModal";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { Category, SubTypesPayload, TypesPayload } from "../../../models/directoryModel";
import {
    getItemCreateOrder,
    updateItemCreateOrder
} from "../../../store/order/selectors";
import { 
    SERVICE_CATEGORY_ID,
    SERVICE_CATEGORY_NAME,
    SERVICE_TYPE_ID,
    SERVICE_TYPE_NAME,
    SERVICE_SUB_TYPE_IDS,
    IS_PRIVATE,
    SERVICE_CATEGORY_SLUG,
    SERVICE_TYPE_SLUG,
    SERVICE_SUB_TYPE_SLUG
} from "../../../store/order/constant";
import { useActions } from "../../../hooks/actions";
import { getItemStore, scrollTop } from "../../../untils";
import { entries } from "lodash";
import { createOrderState } from "../../../store/order/createOrder.slice";

interface CreateOrderModalProps {
    entities?: TranslationObject;
    children: ReactNode;
    category: Category;
    type: TypesPayload;
    classes?: string;
    subtype?: SubTypesPayload;
}

const CreateOrderModal: React.FC<CreateOrderModalProps> = (props) => {
    const { entities, children, category, type, subtype = null, classes="" } = props;
    const isAllowSelectSpec = getItemCreateOrder("isAllowSelectSpecialist");
    const { updateCreateOrderField, clearCreateOrderFields } = useActions();
    const currentLanguage = getCurrentLanguage();
    const navigate = useNavigate();

    const [visible, setVisible] = useState<boolean>(false);

    const closeModal = () => {
        setVisible(false);
    };

    const openModal = () => {
      setVisible(true);
    };

    const updateField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    }

    const setCreateOrderValues = (isPrivate: boolean, step: string) => {
        updateField<typeof SERVICE_CATEGORY_ID>(SERVICE_CATEGORY_ID, category?.id || null)
        updateField<typeof SERVICE_CATEGORY_NAME>(SERVICE_CATEGORY_NAME, category?.name || null)
        updateField<typeof SERVICE_CATEGORY_SLUG>(SERVICE_CATEGORY_SLUG, category?.slug || 'category')
        updateField<typeof SERVICE_TYPE_ID>(SERVICE_TYPE_ID, type?.id || null)
        updateField<typeof SERVICE_TYPE_NAME>(SERVICE_TYPE_NAME, type?.name || null)
        updateField<typeof SERVICE_TYPE_SLUG>(SERVICE_TYPE_SLUG, type?.slug || 'type')
        if (subtype) {
            updateField<typeof SERVICE_SUB_TYPE_IDS>(SERVICE_SUB_TYPE_IDS, subtype?.id ? [subtype?.id] : [])
            updateField<typeof SERVICE_SUB_TYPE_SLUG>(SERVICE_SUB_TYPE_SLUG, subtype?.slug || 'subtype')
        }

        updateItemCreateOrder(
            {
                name: "activeStep",
                value: step,
            },
            updateCreateOrderField
        );
        updateItemCreateOrder(
            {
                name: IS_PRIVATE,
                value: isPrivate,
            },
            updateCreateOrderField
        );
    }

    const createOrder = async (isPrivate: boolean) => {
        let step = `category-${category?.slug}/type-${type?.slug}`;
        step =  subtype
            ? !isAllowSelectSpec
                ? isPrivate
                    ? `${step}/${subtype?.slug ? `subtype-${subtype?.slug}` : 'list-of-specialists'}`
                    :`meeting-point`
                : `${step}/${subtype?.slug ? `subtype-${subtype?.slug}` : 'list-of-specialists'}`
            : step;

        const is_private = getItemStore(IS_PRIVATE);

        if (is_private) {
            await localStorage.clear();
            clearCreateOrderFields();
        }

        setCreateOrderValues(isPrivate, step);
        navigate(`/${currentLanguage}/create-order/${step}`);
        scrollTop();
    };

    return (
        <>
            <div
                onClick={openModal}
                className={`modal__button--open ${classes}`}
            >
                { children }
            </div>
            <Modal
                title={entities?.create_order_link_value?.value}
                description={entities?.order_place?.value}
                visible={visible}
                onClose={closeModal}
            >
                <div className="modal__content-buttons">
                    <button
                        className="btn"
                        type="button"
                        onClick={() => createOrder(false)}
                    >
                        {entities?.order_place_specialist_send?.value}
                    </button>
                    <button
                        className="btn modal__content-button btn--bg-green"
                        type="button"
                        onClick={() => createOrder(true)}
                    >
                        {entities?.order_place_specialist_select?.value}
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default CreateOrderModal;
