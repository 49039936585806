import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { endpoints } from "../../store/user/user.api";
import {
    endpoints as endpointsReview,
    useGetSpecialistPageTranslateMutation,
} from "../../store/review/review.api";
import ReviewCard from "../ReviewCard";
import ServiceTypeCard from "./ServiceTypeCard";
import { ProfilePayload, Service } from "../../models/userModels";
import { TranslationObject } from "../../models/translationModal";
import { getItemStore } from "../../untils";
import { IS_INDIVIDUAL, IS_PRIVATE } from "../../store/order/constant";
import { useActions } from "../../hooks/actions";
import { createOrderState } from "../../store/order/createOrder.slice";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import HeaderSpecialist from "./Header";
import Loader from "../Loader";
import RightSidebar from "./RightSidebar";
import classNames from "classnames";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { checkUtm } from "../../untils/reg";
import { SpecialistDraftNotidication } from "../SpecialistDraftNotidication/SpecialistDraftNotidication";
import { useLogout } from "../../hooks/useLogout/useLogout";
import NotFoundPage from "../../pages/NotFound";
import { transliterate } from 'transliteration';

const SpecialistInfo: React.FC<{
    entities?: TranslationObject;
    isOrder?: boolean;
    changeSpecialistIds?: (value: number) => void;
    specialistIds?: number[];
    pathNameRole?: string;
    isEdit?: boolean;
    profile?: ProfilePayload;
}> = ({
    isEdit,
    entities,
    changeSpecialistIds,
    isOrder,
    specialistIds,
    pathNameRole,
    profile
}) => {
        const currentLanguage = getCurrentLanguage();
        const { id } = useParams();
        const navigate = useNavigate();
        const { updateCreateOrderField } = useActions();
        const [getSpecialist, { data: user, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError, error, isUninitialized }] =
            endpoints.getSpecialist.useLazyQuery();

        const [
            getSpecialistDetail,
            { data: details, isLoading: isLoadingDetails, isUninitialized: isUninitializedDet },
        ] = endpoints.getSpecialistDetail.useLazyQuery();

        const [getReviews, { data: reviews, isLoading: isLoadingReviews }] =
            endpointsReview.getReviewsBySpecialistId.useLazyQuery();

        const [getSpecialistPageTranslate, { data: pageTranslate }] =
            useGetSpecialistPageTranslateMutation();

        const logout = useLogout();

        const [showNotFound, setShowNotFound] = useState(false);

        const fromIndividualOrder =
            getItemStore(IS_INDIVIDUAL) || checkUtm();

        const defaultLink = isOrder
            ? `/${currentLanguage}/customer/${isEdit ? "edit-order/select-specialists" : "create-order"
            }/list-of-specialists`
            : undefined;
        const hasErrorInAddress = getItemCreateOrder("hasErrorInAddress");
        const isAllowSelectSpec = getItemCreateOrder("isAllowSelectSpecialist");
        const showAddButton = true;
        const isLoading = isLoadingUser || isLoadingDetails || isLoadingReviews;
        const titleAbout = entities?.common_about_me?.value;

        const isNotDraftUser = !user?.is_draft;
        const isRegisteredDraftUser = user?.is_draft && !!user?.registered_at;
        const processedName = user?.name ? transliterate(user.name.trim().replace(/\s+/g, '-')).toLowerCase() : 'noname';

        const numericId = parseInt(id, 10);
        const isIdValidNumber = !isNaN(numericId) && isFinite(numericId);

        const [link, setLink] = useState('')

        const extractNumber = (value: string): number | null => {
            const match = value.match(/-(\d+)$/);
            return match ? parseInt(match[1], 10) : null;
        };

        const extractedId  = extractNumber(id || '') ?? Number(id);

        useEffect(() => {
            setLink(window?.history?.state?.usr || '')
    
            return () => {
                clearHistoryState()
            }
        }, [])

        useEffect(() => {
            window.addEventListener("beforeunload", clearHistoryState);
            return () => {
              window.removeEventListener("beforeunload", clearHistoryState);
            };
        }, []);

        const clearHistoryState = () => {
            window.history.replaceState({}, '')
        };

        useEffect(() => {
            if (!isLoadingUser && user) {
                const isNotDraftUser = !user?.is_draft;
                if (isIdValidNumber && isNotDraftUser) {
                    setShowNotFound(true);
                }
            }
        }, [isLoadingUser, user, isIdValidNumber]);

        useEffect(() => {
            if (isNaN(extractedId)) {
                navigate(profile?.user_role ? `/${currentLanguage}/${profile?.user_role}/not-found` :  `/${currentLanguage}/not-found`);
            }

            if (!isUninitialized) { 
                if (!user) {
                    navigate(profile?.user_role ? `/${currentLanguage}/${profile?.user_role}/not-found` :  `/${currentLanguage}/not-found`);
                }

                if (!isNotDraftUser && profile && profile?.id !== user?.id) {
                    logout(false);
                }
            }
        }, [user])

        useEffect(() => {
            if (hasErrorInAddress) {
                updateField({
                    name: "hasErrorInAddress",
                    value: false,
                });
            }

            if (extractedId) {
                const data = { id: extractedId};

                getSpecialist(data)
                    .unwrap()
                    .catch((error) => {
                        if (error?.status === 404) {
                            navigate(profile?.user_role ? `/${currentLanguage}/${profile?.user_role}/not-found` :  `/${currentLanguage}/not-found`);
                        }
                    });
                getSpecialistDetail(data);

                (isNotDraftUser || isRegisteredDraftUser) && getReviews({
                    id: extractedId,
                    params: {
                        per_page: 3,
                    },
                });
            }
        }, [extractedId, hasErrorInAddress]);

        const updateField = <K extends keyof createOrderState>({
            name,
            value,
        }: {
            name: K;
            value: createOrderState[K];
        }) => {
            updateItemCreateOrder(
                {
                    name,
                    value,
                },
                updateCreateOrderField
            );
        };

        const handleClickTranslatePage = () => {
            const userId = user?.id;
            if (userId) {
                getSpecialistPageTranslate(userId);
            }
        };

        const linkToProfile = `/${currentLanguage}/specialist/lk/profile`;
        const isSpecialist = profile?.user_role === "specialist";

        if (showNotFound) {
            return <NotFoundPage />;
        }

        return isLoading ? (
            <Loader />
        ) : (
            <>
                <main>
                    <div className="page">
                        <section className="specialist-page">
                            <div className="inner-container">
                                <HeaderSpecialist
                                    entities={entities}
                                    user={user}
                                    defaultLink={defaultLink}
                                    details={details}
                                    handleClickTranslatePage={handleClickTranslatePage}
                                    pageTranslate={pageTranslate}
                                    isShowBackLink={isNotDraftUser || isRegisteredDraftUser}
                                />
                                <div className="specialist-page__wrapper">
                                    <div className="specialist-page__content">
                                        <div className="specialist-page__about">
                                            <h2 className="title title--f32">
                                                {titleAbout}
                                            </h2>
                                            <p className="text">
                                                {pageTranslate?.about_me ||
                                                    user?.about_me}
                                            </p>
                                            {details?.achievements?.length > 0 && (
                                                <>
                                                    <h2 className="title title--f32">
                                                        {
                                                            entities
                                                                ?.specialist_details_professional_achievements
                                                                ?.value
                                                        }
                                                    </h2>
                                                    <ul className="list-reset prof-list">
                                                        {details?.achievements?.map(
                                                            (elem, key) => (
                                                                <li
                                                                    key={key + elem?.id}
                                                                    className={classNames(
                                                                        "prof-list__item",
                                                                        {
                                                                            popover:
                                                                                elem?.documents_is_verified,
                                                                        }
                                                                    )}
                                                                >
                                                                    {elem?.documents_is_verified ? (
                                                                        <span className="popover__tip">
                                                                            {
                                                                                entities
                                                                                    ?.specialist_details_confirmed_by_document
                                                                                    ?.value
                                                                            }
                                                                        </span>
                                                                    ) : null}
                                                                    {elem?.documents_is_verified ? (
                                                                        <span className="color-neon-blue">
                                                                            ✓
                                                                        </span>
                                                                    ) : (
                                                                        <span className="prof-list__bullet">
                                                                            •
                                                                        </span>
                                                                    )}{" "}
                                                                    {pageTranslate?.achievements?.find(
                                                                        (el) =>
                                                                            el?.id === elem?.id
                                                                    )?.title ||
                                                                        elem?.title}{" "}
                                                                    {elem?.year}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </>
                                            )}
                                        </div>
                                        <div className="specialist-page__about">
                                            <h2 className="title title--f32">
                                                { entities?.services_provided_title?.value }
                                            </h2>
                                            {details?.services?.map(
                                                (serviceType: Service, key: number) => (
                                                    <ServiceTypeCard
                                                        serviceType={serviceType}
                                                        key={key + serviceType?.id}
                                                        entities={entities}
                                                        serviceTypeTranslate={
                                                            pageTranslate?.services?.find(
                                                                (el) =>
                                                                    el?.id ===
                                                                    serviceType?.id
                                                            ) || null
                                                        }
                                                        profile={profile}
                                                    />
                                                )
                                            )}
                                        </div>
                                        {
                                            ((isNotDraftUser || isRegisteredDraftUser) && reviews?.items?.length > 0) &&
                                            <>
                                                <h2 className="title title--f32">
                                                    { entities?.specialist_details_reviews?.value }
                                                </h2>
                                                <div className="reviews">
                                                    <ul className="reviews__list list-reset">
                                                        {reviews?.items?.map((review) => (
                                                            <ReviewCard
                                                                entities={entities}
                                                                review={review}
                                                                key={review.id}
                                                            />
                                                        ))}
                                                    </ul>
                                                    {reviews && reviews?.total > 3 && (
                                                        <Link
                                                            className="btn reviews__btn btn--white btn--wide btn--rounded"
                                                            to={`/${currentLanguage}/specialist/${processedName}-${user?.id}/reviews`}
                                                        >
                                                            {
                                                                entities
                                                                    ?.specialist_details_show_more_reviews
                                                                    ?.value
                                                            }
                                                        </Link>
                                                    )}
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {
                                        (isNotDraftUser || isRegisteredDraftUser) &&
                                            <RightSidebar
                                                user={user}
                                                showAddButton={showAddButton}
                                                entities={entities}
                                                pathNameRole={pathNameRole}
                                                updateField={updateField}
                                                details={details}
                                                profile={profile}
                                                fromIndividualOrder={fromIndividualOrder}
                                                defaultLink={defaultLink}
                                                changeSpecialistIds={changeSpecialistIds}
                                                isAllowSelectSpec={isAllowSelectSpec}
                                                link={link}
                                            />
                                    }
                                </div>
                                {isSpecialist && (isNotDraftUser || isRegisteredDraftUser) && (
                                    <div className="specialist-page__controls">
                                        <Link className="btn specialist-page__btn-text" to={linkToProfile} state={window.location.pathname}>
                                            {entities?.specialist_details_preview_close?.value} {pathNameRole}
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </section>
                    </div>
                </main>
                {
                    (!isNotDraftUser && !isRegisteredDraftUser) && 
                        <SpecialistDraftNotidication 
                            entities={entities} 
                            id={id}
                        />
                }
            </>
        );
    };

export default SpecialistInfo;
