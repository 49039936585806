import { useEffect } from "react";
import { endpoints } from "../store/directories/directories.api";
import { useParams } from "react-router-dom";
import { getItemCreateOrder, updateItemCreateOrder } from "../store/order/selectors";
import { createOrderState } from "../store/order/createOrder.slice";
import { SERVICE_CATEGORY_ID, SERVICE_CATEGORY_NAME, SERVICE_CATEGORY_SLUG, SERVICE_TYPE_ID, SERVICE_TYPE_NAME, SERVICE_TYPE_SLUG } from "../store/order/constant";
import { useActions } from "./actions";
import { ProfilePayload } from "../models/userModels";

export const useGetUrlCategories = (currentLanguage?: string, profile?: ProfilePayload) => {
  const { category, type: pathType, subtype: pathSubtype } = useParams();
  const { data: serviceCategories } = endpoints.getServiceCategories.useQuery();
  const activeType = getItemCreateOrder(SERVICE_TYPE_ID);
  const activeCategory = getItemCreateOrder(SERVICE_CATEGORY_ID);
  const { updateCreateOrderField } = useActions();
  const [getServiceTypes, { data: serviceTypes }] = endpoints.getServiceTypes.useLazyQuery();

  const updateField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
    updateItemCreateOrder(
        { 
            name,
            value
        },
        updateCreateOrderField
    );
  };

  useEffect(() => {
    if (!serviceCategories?.length) return;

    const slugCat = category?.replace(/^category-/gi, '') || ''; 
    const selectedCat = serviceCategories?.find(item => item.slug === slugCat || item.id === activeCategory);

    if (selectedCat?.slug && (category !== `category-${selectedCat.slug}`)) {
        updateField<typeof SERVICE_CATEGORY_SLUG>(SERVICE_CATEGORY_SLUG, selectedCat.slug ? `category-${selectedCat.slug}` : 'category');
        // history.pushState(null, "",
        //     !pathType
        //         ? `/${currentLanguage}/create-order/category-${selectedCat.slug}/list-of-specialists`
        //         : `/${currentLanguage}/create-order/category-${selectedCat.slug}/${pathType}/${pathSubtype || 'subtype'}`
        // );
    }

    if (selectedCat?.id && !activeCategory) {
        updateField<typeof SERVICE_CATEGORY_ID>(SERVICE_CATEGORY_ID, selectedCat.id);
    }

    updateField<typeof SERVICE_CATEGORY_NAME>(SERVICE_CATEGORY_NAME, selectedCat?.name || null);
  }, [serviceCategories])

  useEffect(() => {
    if (activeCategory) {
        getServiceTypes({
            id: activeCategory,
        });
    }
  }, [activeCategory]);

  useEffect(() => {
    if (!serviceTypes?.length) return;

    const slugType = pathType?.replace(/^type-/gi, '') || ''; 
    const selectedType= serviceTypes?.find(item => item.slug === slugType || item.id === activeType);

    if (selectedType?.slug && (pathType !== `type-${selectedType.slug}`)) {
        updateField<typeof SERVICE_TYPE_SLUG>(SERVICE_TYPE_SLUG, selectedType.slug ? `type-${selectedType.slug}` : 'type');
        // history.pushState(null, "", `/${currentLanguage}/create-order/${category}/type-${selectedType.slug}/${pathSubtype || 'subtype'}`);
    }

    if (selectedType?.id && !activeType) {
        updateField<typeof SERVICE_TYPE_ID>(SERVICE_TYPE_ID, selectedType.id);
    }

    updateField<typeof SERVICE_TYPE_NAME>(SERVICE_TYPE_NAME, selectedType?.name || null);
  }, [serviceTypes])
}

