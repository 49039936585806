import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {endpoints} from "../../../store/directories/directories.api";
import {
    SERVICE_CATEGORY_ID,
    SERVICE_TYPE_ID,
    SERVICE_TYPE_NAME,
    SERVICE_TYPE_SLUG,
} from "../../../store/order/constant";
import {
    getItemCreateOrder,
    getItemEditOrder,
    updateItemCreateOrder
} from "../../../store/order/selectors";
import { createOrderState } from "../../../store/order/createOrder.slice";
import { useActions } from "../../../hooks/actions";
import { TranslationObject } from "../../../models/translationModal";

const TypeItem: React.FC<{
    currentLanguage: string;
    isEdit: boolean;
    entities?: TranslationObject;
}> = ({currentLanguage, isEdit, entities}) => {
    const {updateCreateOrderField} = useActions();
    const {category, type: pathType} = useParams();
    const navigate = useNavigate();
    const id = isEdit
        ? getItemEditOrder(SERVICE_TYPE_ID)
        : getItemCreateOrder(SERVICE_TYPE_ID);

    const activeCategory = getItemCreateOrder(SERVICE_CATEGORY_ID) || null;
    const [getServiceTypes, {data: serviceTypes}] = endpoints.getServiceTypes.useLazyQuery();
    const currentType = serviceTypes?.find((item) => item.id === id) || null;
    const otherTypes = serviceTypes
        ?.filter((item) => item.id !== id)
        ?.sort((a, b) => a.name.localeCompare(b.name)) || [];

    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        if (activeCategory) {
            getServiceTypes({
                id: activeCategory,
            })
        }
    }, [activeCategory, getServiceTypes]);

    useEffect(() => {
        if (!serviceTypes?.length) return;

        const slugType = pathType?.replace(/^type-/gi, "") || "";
        const selectedType = serviceTypes?.find((item) => item.slug === slugType);

        if (selectedType?.id) {
            updateOrderField<typeof SERVICE_TYPE_ID>(SERVICE_TYPE_ID, selectedType.id);
            updateOrderField<typeof SERVICE_TYPE_SLUG>(SERVICE_TYPE_SLUG, `type-${selectedType.slug}`);
            updateOrderField<typeof SERVICE_TYPE_NAME>(SERVICE_TYPE_NAME, selectedType?.name || null);
        }
    }, [serviceTypes, pathType]);

    const updateOrderField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
        updateField({
            name,
            value,
        });
    };

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    const handleCategoryClick = (item: any) => {
        updateOrderField<typeof SERVICE_TYPE_ID>(SERVICE_TYPE_ID, item.id);
        updateOrderField<typeof SERVICE_TYPE_SLUG>(SERVICE_TYPE_SLUG, `type-${item.slug}`);
        updateOrderField<typeof SERVICE_TYPE_NAME>(SERVICE_TYPE_NAME, item.name);

        navigate(`/${currentLanguage}/create-order/${category}/type-${item.slug}/list-of-specialists/`);
    };

    const toggleShowAll = () => {
        setShowAll((prevShowAll) => !prevShowAll);
    };

    return (
        <>
            {currentType && (
                <div className="form__row">
                    <div className="link" style={{pointerEvents: "none", cursor: "default"}}>
                        <Link
                            to={`/${currentLanguage}/create-order/${category}/type-${currentType.slug}/list-of-specialists/`}
                            style={{fontWeight: "bold"}}
                            onClick={(event) => event.preventDefault()}
                        >
                            {currentType.name}
                        </Link>
                    </div>
                </div>
            )}

            {(showAll ? otherTypes : otherTypes?.slice(0, 6))?.map((item, index) => (
                <div className="form__row" key={index}>
                    <div className="link">
                        <Link
                            to={`/${currentLanguage}/create-order/${category}/type-${item.slug}/list-of-specialists/`}
                            onClick={(e) => {
                                e.preventDefault();
                                handleCategoryClick(item);
                            }}
                        >
                            {item.name}
                        </Link>
                    </div>
                </div>
            ))}

            {otherTypes && (
                <div className="form__row">
                    <div className="link">
                        {!showAll && otherTypes.length > 6 && (
                            <button
                                className="link link--underline"
                                onClick={toggleShowAll}
                                type="button"
                            >
                                { entities?.order_show_all?.value }
                            </button>
                        )}
                        {showAll && (
                            <button
                                className="link link--underline"
                                onClick={toggleShowAll}
                                type="button"
                            >
                                { entities?.order_hide?.value }
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default TypeItem;
