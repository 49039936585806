import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { IAdvertisementSpecialistBanner } from "../../models/advertisementModel";
import _ from "lodash";
import { ProfilePayload } from "../../models/userModels";
import { createOrderState } from "../../store/order/createOrder.slice";
import {
	clearOrderDataInLocalStorage,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import { useActions } from "../../hooks/actions";
import {
	IS_PRIVATE,
	SERVICE_CATEGORY_ID,
	SERVICE_TYPE_ID,
	SERVICE_SUB_TYPE_IDS,
	SERVICE_CATEGORY_SLUG,
	SERVICE_TYPE_SLUG,
	SERVICE_SUB_TYPE_SLUG,
	SERVICE_TYPE_NAME
} from "../../store/order/constant";
import ModalSelectCategory from "../SpecialistInfo/ModalSelectCategory";
import { TranslationObject } from "../../models/translationModal";
import { endpoints } from "../../store/directories/directories.api";
import { TypesPayload } from "../../models/directoryModel";
import Modal from "../Modal";
import { useLogout } from "../../hooks/useLogout/useLogout";

interface AdvertisingBannerProps {
	advertisementSpecialists: IAdvertisementSpecialistBanner;
	profile?: ProfilePayload
	entities?: TranslationObject
}

const AdvertisingBanner: React.FC<AdvertisingBannerProps> = ({
	advertisementSpecialists,
	profile,
	entities
}) => {
	const {
		photo,
		service_texts,
		category,
		category_slug,	
		service_type,
		service_type_slug,
		service_sub_type,
		service_sub_type_slug
	} = advertisementSpecialists || {};

	const [showModal, setShowModal] = useState<boolean>(false);
	const [showModalLogout, setShowModalLogout] = useState<boolean>(false);
	const { updateCreateOrderField } = useActions();
	const navigate = useNavigate();
	const currentLanguage = getCurrentLanguage();
	const [getServiceTypes, { data: serviceTypes }] = endpoints.getServiceTypes.useLazyQuery();
	const subCategories = serviceTypes?.reduce(
		(arr: TypesPayload[], current: TypesPayload) => {
			if (service_type.includes(current.id)) {
				arr.push(current)
			}
			return arr
		},[]
	);

	const isSpecialistRole = profile?.user_role === 'specialist';
	const logout = useLogout();

	useEffect(() => {
        if (category) {
            getServiceTypes({
                id: category,
            });
        }
	}, [category]);

	const updateField = <K extends keyof createOrderState>({
            name,
            value,
        }: {
            name: K;
            value: createOrderState[K];
        }) => {
            updateItemCreateOrder(
                { name, value },
                updateCreateOrderField
            );
        };

	const handleClick = async () => {
		clearOrderDataInLocalStorage(false, []);

		if (service_type?.length > 1) {
			return setShowModal(true);
		}

		const items: {
			name: keyof createOrderState;
			value: createOrderState[keyof createOrderState];
		}[] = [
			{ name: IS_PRIVATE, value: true },
			{ name: SERVICE_CATEGORY_ID, value: category },
			{ name: SERVICE_CATEGORY_SLUG, value: `category-${category_slug}` },
			{ name: SERVICE_TYPE_ID, value: service_type[0]},
			{ name: SERVICE_TYPE_NAME, value: service_type[0]?.name },
			{ name: SERVICE_TYPE_SLUG, value: `type-${service_type_slug[service_type[0]]}`}
		];

		if (service_sub_type) {
			items.push(
				{ name: SERVICE_SUB_TYPE_IDS, value: service_sub_type[0] },
				{ name: SERVICE_SUB_TYPE_SLUG, value: `subtype-${service_sub_type_slug[service_sub_type[0]]}` },
				{ name: "activeStep", value: `category-${category_slug}/type-${service_type_slug[service_type[0]]}/subtype-${service_sub_type_slug[service_sub_type[0]]}/` }
			)
		} else {
			items.push( {
				name: "activeStep",
				value: `category-${category_slug}/type-${service_type_slug[service_type[0]]}/`,
			})
		}

		items.forEach((elem) => {
			updateField({
				name: elem.name,
				value: elem.value,
			});
		});

		if (profile?.id) {
			updateField({
				name: "userId",
				value: profile?.id,
			});
		}

		setTimeout(() => {
			if (service_sub_type) {
				return navigate(`/${currentLanguage}/create-order/category-${category_slug}/type-${service_type_slug[service_type[0]]}/subtype-${service_sub_type_slug[service_sub_type[0]]}/`);
			}
			return navigate(`/${currentLanguage}/create-order/category-${category_slug}/type-${service_type_slug[service_type[0]]}/`);
		}, 50);
	};

	const handleLogout = () => {
		logout();
		setShowModalLogout(false);

		setTimeout(() => handleClick(), 10);
	};

	return (
		<li
			onClick={() => isSpecialistRole ? handleLogout() : handleClick()}
			className="specialists__item"
		>
			{isSpecialistRole && <Modal
				title={entities?.common_offer_order?.value}
				description={entities?.profile_logout_specialist?.value}
				visible={showModalLogout}
				onClose={() => setShowModalLogout(false)}
			>
				<div className="modal__content-buttons">
					<button
						className="btn btn--transparent"
						type="button"
						onClick={() => setShowModalLogout(false)}
					>
						{entities?.common_cancel_button?.value}
					</button>
					<button
						className="btn modal__content-button btn--bg-green"
						type="button"
						onClick={handleLogout}
					>
						{entities?.profile_logout_button_ok?.value}
					</button>
				</div>
			</Modal>}
			{service_type?.length > 1 && <ModalSelectCategory
				entities={entities}
				visible={showModal}
				close={() => setShowModal(false)}
				categories={subCategories}
				cat={category}
				cat_slug={category_slug}
				updateField={updateField}
				profileId={profile?.id}
				isIndividual={false}
			/>}
			<div>
				<div className="specialists__item-wrapper">
					<img
						src={photo?.original_url}
						width="255"
						height="192"
						alt={service_texts[0] || "avatar"}
					/>
				</div>
				<ul className="specialists__item-skills-list no-scrollbar">
					{service_texts?.map((service) => (
						<li
							className="specialists__item-skills-item"
							key={_.uniqueId("service")}
						>
							<p>{service}</p>
						</li>
					))}
				</ul>
			</div>
		</li>
	);
};

export default AdvertisingBanner;
