import React, { useEffect, useState } from "react";
import Form from "../../components/Form/Form";
import { OrderStepsProps } from "./index";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import {
    IS_MY_ADDRESS,
    IS_REMOTE,
    IS_SPECIALIST_ADDRESS,
    SERVICE_CATEGORY_NAME,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_TYPE_NAME,
    SERVICE_TYPE_SLUG,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_CATEGORY_SLUG,
    IS_PRIVATE,
    FILTER_PAGES,
    IS_INDIVIDUAL,
} from "../../store/order/constant";
import CheckboxAddress from "../../components/CheckboxAddress";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import { createOrderState } from "../../store/order/createOrder.slice";
import { useActions } from "../../hooks/actions";
import Advertising from "../../components/Advertising";
import CancelOrderButton from "./Specialists/CancelOrderButton";
import { scrollTop } from "../../untils";
import Breadcrumbs from "../../components/Breadcrumbs";

interface Props extends OrderStepsProps {
    hasFilters: boolean;
}

const SelectWhereToMeet: React.FC<Props> = ({
    onSubmit,
    onSuccess,
    entities,
    profile,
    isSuccess,
    hasFilters,
}) => {
    const navigate = useNavigate();
    const { updateCreateOrderField } = useActions();
    const currentLanguage = getCurrentLanguage();
    const filterPages = getItemCreateOrder(FILTER_PAGES) || [];
    const isPrivate = getItemCreateOrder(IS_PRIVATE) || false;
    const isIndividual = getItemCreateOrder(IS_INDIVIDUAL) || false;
    const isMyAddress = getItemCreateOrder(IS_MY_ADDRESS);
    const isSpecialistAddress = getItemCreateOrder(IS_SPECIALIST_ADDRESS);
    const isRemote = getItemCreateOrder(IS_REMOTE);
    const [addressTypes, setAddressTypes] = useState<string[]>([]);
    const serviceSubTypesIdsLength = getItemCreateOrder(SERVICE_SUB_TYPE_IDS)?.length;
    const subTypeName = getItemCreateOrder(SERVICE_SUB_TYPE_NAME);
    const typeName = getItemCreateOrder(SERVICE_TYPE_NAME);
    const serviceCategoryName = getItemCreateOrder(SERVICE_CATEGORY_NAME);

    const categoryPath = getItemCreateOrder(SERVICE_CATEGORY_SLUG);
    const typePath = getItemCreateOrder(SERVICE_TYPE_SLUG);
    const subtypePath = getItemCreateOrder(SERVICE_SUB_TYPE_SLUG);

    const back = () => {
        navigate(
            typePath
                ? (!isPrivate || isIndividual
                    ? `/${currentLanguage}/create-order/${hasFilters ? "filters" : `${categoryPath}/${typePath || 'type'}`}`
                    : `/${currentLanguage}/create-order/${hasFilters ? "filters" : `${categoryPath}/${typePath || 'type'}/${subtypePath || 'subtype'}`}`)
                : `/${currentLanguage}/create-order/${hasFilters ? "filters" : (!isPrivate ? `${categoryPath}` : `${categoryPath}/list-of-specialists`)}`
        );
        scrollTop();
    };

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    useEffect(() => {
        const steps = [];
        if (isMyAddress) steps.push(IS_MY_ADDRESS);
        if (isSpecialistAddress) steps.push(IS_SPECIALIST_ADDRESS);
        if (isRemote) steps.push("is_remote");
        setAddressTypes(steps);
    }, [isMyAddress, isSpecialistAddress, isRemote]);

    const handleChange = (name: keyof createOrderState) => {
        if (addressTypes.indexOf(name) !== -1) {
            updateField({
                name,
                value: false,
            });
            return setAddressTypes(
                addressTypes.filter((elem) => elem !== name)
            );
        }
        updateField({
            name,
            value: true,
        });
        setAddressTypes(addressTypes.concat(name));
    };

    const _onSuccess = () => {
        onSuccess(getNextStep());
    };

    const getNextStep = () => {
        let nextStep = "when-to-start";

        if (addressTypes.includes("is_my_address")) {
            nextStep = "select-my-address";
            return nextStep;
        }
        if (
            !addressTypes.includes("is_my_address") &&
            addressTypes.includes("is_specialist_address")
        ) {
            nextStep = "select-specialist-address";
            return nextStep;
        }

        return nextStep;
    };

    const _onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        onSubmit(e, getNextStep());
    };

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const crumbs = [
        {
            name: serviceCategoryName || entities?.service_task?.value,
            link: `create-order/${categoryPath}`
        },
        ...(typePath ? [
            {
                name: typeName || entities?.type_of_service_2?.value,
                link: `create-order/${categoryPath}/${typePath}`
            }
        ] : []),
        ...(isPrivate && !isIndividual ? [
            {
                name: subTypeName
                    ? `${subTypeName} - ${entities?.choosing_a_specialist?.value}`
                    : serviceSubTypesIdsLength
                        ? `${entities?.subtype_of_service_2?.value} (${serviceSubTypesIdsLength}) - ${entities?.choosing_a_specialist?.value}`
                        : entities?.choosing_a_specialist?.value,
                link: typePath || typePath
                    ? `create-order/${categoryPath}/${typePath}/${subtypePath || 'subtype'}`
                    : `create-order/${categoryPath}/list-of-specialists`
            }
        ] : []),
        ...(!isPrivate && filterPages.length > 0 ? [
            {
                name: entities?.service_filter?.value,
                link: 'create-order/filters'
            }
        ] : []),
        {
            active: true,
            name: entities?.common_meeting_place?.value
        }
    ]

    return (
        <div className="inner-container">
            <div className="new-order__wrapper">
                <Breadcrumbs
                    crumbs={crumbs}
                />
                <div className="new-order__header">
                    <h2 className="title title--f46">
                        {entities?.order_select_location_title?.value}
                    </h2>
                    <CancelOrderButton profile={profile} entities={entities} />
                </div>
                <div className="new-order__form form">
                    <Form
                        onSubmit={_onSubmit}
                        isSuccess={isSuccess}
                    >
                        {(invalidFields, error) => (
                            <>
                                <CheckboxAddress
                                    entities={entities}
                                    error={error}
                                    invalidFields={invalidFields}
                                    currentAddresses={addressTypes}
                                    handleChange={handleChange}
                                />
                                <div className="form__row form__controls">
                                    <CancelOrderButton
                                        profile={profile}
                                        entities={entities}
                                    />
                                    <button
                                        className="btn form__back-link btn--transparent"
                                        type="button"
                                        onClick={back}
                                    >
                                        {entities?.common_back_button?.value}
                                    </button>
                                    <button
                                        className="btn btn--transparent btn--bg-blue"
                                        type="submit"
                                        disabled={addressTypes.length === 0}
                                    >
                                        {entities?.login_continue_button?.value}
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </div>
            <ul className="advertising-list create-order">
                <Advertising countCards={5} entities={entities} isHorizontal={true} />
            </ul>
        </div>
    );
};
export default SelectWhereToMeet;
