import React, { useEffect, useState } from "react";
import StepOneRegister from "./StepOneRegister";
import EditPassword from "../../components/EditPassword";
import { getTkn } from "../../store/user/selectors";
import { getTokenCookie } from "../../store/request";
import StepTwoRegister from "./StepTwoRegister";
import SubMenu from "../../components/SubMenu";
import { ComponentProps } from "../../models/models";
import RegisterForm from "../../components/Form/RegisterForm";
import { ProfilePayload } from "../../models/userModels";
import { StepCompanyOrPrivatePerson } from "./StepCompanyOrPrivatePerson";
import { StepCompanyData } from "./StepCompanyData";
import { SpecialistQuestionnaire } from "./SpecialistQuestionnaire/SpecialistQuestionnaire";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../untils/cookies";

interface Steps {
    one: Element;
    two: Element;
    three: Element;
}

interface StepsSpecialist extends Steps {
    four: Element;
    five: Element;
    six: Element;
}

interface Props extends ComponentProps {
    profile?: ProfilePayload;
}

const Registration: React.FC<Props> = ({
    entities,
    isCustomerOnUrl,
    isSpecialistOnUrl,
    currentLanguage,
    profile,
}) => {
    const [step, setStep] = useState<string>("one");
    const token = getTkn() || getTokenCookie();
    const navigate = useNavigate();

    useEffect(() => {
        if (
            (profile?.user_role === "specialist" && profile?.id && !profile?.has_password && token) ||
            (profile?.user_role === "customer" && profile?.id && !profile?.registered_at && token)
        ) {
            if (profile.user_role === "specialist" && !isSpecialistOnUrl) {
                navigate(`/${currentLanguage}/specialist/registration`);
            } else if (profile.user_role === "customer" && isSpecialistOnUrl) {
                navigate(`/${currentLanguage}/customer/registration`);
            }

            if (profile?.is_draft) {
                setStep(profile?.company || profile?.is_company ? "three" : "four");
                return;
            }

            return setStep("two");
        }

        if (profile?.id && profile?.registered_at && getCookie("tkn")) {
            if (profile.user_role === "customer" && isCustomerOnUrl) {
                return navigate(
                    `/${currentLanguage}/${profile?.user_role}/lk/profile`
                );
            }

            if (
                profile?.user_role === "specialist" &&
                profile?.published_at &&
                isSpecialistOnUrl
            ) {
                return navigate(
                    `/${currentLanguage}/${profile?.user_role}/lk/profile`
                );
            }
        }
    }, [profile]);

    const steps = {
        one: (
            <StepOneRegister
                currentLanguage={currentLanguage}
                entities={entities}
                changeStep={setStep}
                isCustomerOnUrl={isCustomerOnUrl}
            />
        ),
        two: (
            <StepTwoRegister
                currentLanguage={currentLanguage}
                token={token}
                entities={entities}
                changeStep={setStep}
                profile={profile}
            />
        ),
        three: (
            <EditPassword
                currentLanguage={currentLanguage}
                token={token}
                entities={entities}
                changeStep={setStep}
                profile={profile}
            />
        ),
    };
    const stepsSpecialist = {
        one: (
            <StepOneRegister
                isSpecialist={isSpecialistOnUrl}
                isCustomerOnUrl={isCustomerOnUrl}
                currentLanguage={currentLanguage}
                entities={entities}
                changeStep={setStep}
            />
        ),
        two: (
            <StepCompanyOrPrivatePerson
                currentLanguage={currentLanguage}
                entities={entities}
                changeStep={setStep}
            />
        ),
        three: (
            <StepCompanyData
                currentLanguage={currentLanguage}
                entities={entities}
                changeStep={setStep}
                profile={profile}
            />
        ),
        four: (
            <StepTwoRegister
                currentLanguage={currentLanguage}
                token={token}
                entities={entities}
                changeStep={setStep}
                nextStep={"five"}
                prevStep={"two"}
                profile={profile}
            />
        ),
        five: (
            <EditPassword
                currentLanguage={currentLanguage}
                token={token}
                entities={entities}
                changeStep={setStep}
                isSpecialist={true}
                prevStep={"four"}
                profile={profile}
            />
        ),
        six: (
            <SpecialistQuestionnaire
                currentLanguage={currentLanguage}
                entities={entities}
            />
        ),
    };

    if (isSpecialistOnUrl && step === "six") {
        return (
            <main>
                <SubMenu entities={entities} profile={profile} />
                {stepsSpecialist[step as keyof StepsSpecialist]}
            </main>
        );
    }

    return (
        <main>
            <SubMenu entities={entities} profile={profile} />
            <RegisterForm
                isCustomerOnUrl={isCustomerOnUrl ?? false}
                isSpecialistOnUrl={isSpecialistOnUrl ?? false}
            >
                {isCustomerOnUrl && steps[step as keyof Steps]}
                {isSpecialistOnUrl &&
                    stepsSpecialist[step as keyof StepsSpecialist]}
            </RegisterForm>
        </main>
    );
};
export default Registration;
