import React, {useEffect, useState} from "react";
import {TranslationObject} from "../../models/translationModal";
import Form from "../Form/Form";
import {endpoints} from "../../store/directories/directories.api";
import {useSetSpecialistServiceTypeMutation} from "../../store/user/user.api";
import {ChangeStepType} from "../../pages/Registration/SpecialistNewService/SpecialistNewService";
import {useHistoryBackHandler} from "../../hooks/useHistoryBackHandler";
import Breadcrumbs from "../../components/Breadcrumbs";
import {TypesPayload} from "../../models/directoryModel";
import cn from "classnames";

interface SpecialistServiceTypeProps {
    entities?: TranslationObject;
    changeStep: ChangeStepType;
    eventClearPreService?: () => unknown;
    setServicePayload?: React.Dispatch<React.SetStateAction<any>>;
    activeCategory: number | null;
    activeSpecialistServices: number | null;
    activeCategoryName: string | null;
    type?: number | string;
    typeName?: string;
}

export const SpecialistServiceTypes: React.FC<SpecialistServiceTypeProps> = (
    props
) => {
    const {
        entities,
        changeStep,
        eventClearPreService,
        setServicePayload,
        activeCategory,
        activeSpecialistServices,
        activeCategoryName,
        type,
        typeName,
    } = props || {};
    // Type ID
    const [activeType, setActiveType] = useState<number | string | null>(type ? type : null);
    // Type Name
    const [activeTypeName, setActiveTypeName] = useState<string>(typeName ? typeName : '');
    const [foundItems, setFoundItems] = useState<TypesPayload[] | null>(null);
    const {data: serviceTypes} = endpoints.getServiceTypes.useQuery(
        {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            id: activeCategory,
        },
        {skip: activeCategory === null}
    );

    const [
        setSpecialistServiceType,
        {isSuccess, isLoading, data: setSpecialistServiceTypeResponse},
    ] = useSetSpecialistServiceTypeMutation();

    useHistoryBackHandler(() => changeStep("category"));

    useEffect(() => {
        const payload = setSpecialistServiceTypeResponse?.payload;
        if (
            isSuccess &&
            payload?.service_type?.id
        ) {
            setServicePayload?.(payload);
            changeStep("subType");
        }
    }, [isSuccess, setSpecialistServiceTypeResponse]);

    useEffect(() => {
        if (type) {
            setActiveType(type);
        }

        if (typeName) {
            setActiveTypeName(typeName);
        }

    }, [type, typeName]);

    useEffect(() => {
        const items = (serviceTypes || [])
        setFoundItems(items);
    }, [serviceTypes, activeType])

    const currentType =
        typeof activeType === "number" &&
        serviceTypes?.find((elem: TypesPayload) => elem.id == activeType)?.name;

    const isSubmitActive =
        !!activeCategory &&
        !!activeSpecialistServices &&
        !!(activeType || activeTypeName) &&
        !isLoading;

    const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isSubmitActive) return;

        const requestData = {
            specialistService: activeSpecialistServices,
            body: {
                ["service_type_id"]: activeType,
            },
        };

        setSpecialistServiceType(requestData);
    };

    const handleClick = (id: number) => {
        if (activeType === id) {
            setActiveType(null);
            return;
        }

        setActiveType(id);

        if (eventClearPreService) {
            eventClearPreService();
        }
    };

    const crumbs = [
        {
            name: activeCategoryName || entities?.service_category?.value,
            link: 'lk/profile?type=service&step=category'
        },
        {
            active: true,
            name: entities?.type_of_service?.value
        }
    ]

    return (
        <>
            <h1 className="visually-hidden">
                {entities?.specialist_details_service_type_provide?.value}
            </h1>
            <div className="inner-container">
                <div className="new-order__wrapper">
                    <Breadcrumbs
                        crumbs={crumbs}
                        isSpecialist={true}
                    />
                    <div className="new-order__header">
                        <h2 className="title title--f46">
                            {
                                entities
                                    ?.specialist_details_service_type_provide
                                    ?.value
                            }
                        </h2>
                        <p className="subtitle">
                            {entities?.specialist_details_select_one_service_type?.value}
                        </p>
                    </div>
                    <div className="specialist new-order__form form">
                        <Form onSubmit={onSubmitForm} entities={entities}>
                            {(invalidFields, error) => {
                                return (
                                    <>
                                        <div className="new-order-start">
                                            <div className="form__row">
                                                {foundItems?.map(
                                                    (elem) => (
                                                        <button
                                                            key={elem?.id}
                                                            className={cn(
                                                                "new-order-start__toggle",
                                                                activeType === elem.id && 'is-active'
                                                            )}
                                                            type="submit"
                                                            onClick={() =>
                                                                handleClick(elem?.id)
                                                            }
                                                        >
                                                            {elem?.name}
                                                        </button>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="form__row form__controls">
                                            <button
                                                onClick={() =>
                                                    changeStep("category")
                                                }
                                                className="btn form__back-link btn--transparent"
                                                type="button"
                                            >
                                                {entities?.common_back_button
                                                    ?.value || ""}
                                            </button>
                                        </div>
                                    </>
                                )
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};
