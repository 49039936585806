import React, {
    useState,
    FC,
    Dispatch,
    SetStateAction,
    useEffect,
} from "react";
import Icon from "../../../../components/Icon";
import TypeItem from "../TypeItem";
import SubTypeItem from "../SubTypeItem";
import { getCurrentLanguage } from "../../../../store/directories/selectors";
import classNames from "classnames";
import { TranslationObject } from "../../../../models/translationModal";
import {
    getItemCreateOrder,
    getItemEditOrder,
    updateItemCreateOrder,
} from "../../../../store/order/selectors";
import {
    FILTER_PAGES,
    IS_MY_ADDRESS,
    IS_REMOTE,
    IS_SPECIALIST_ADDRESS,
    LANGUAGE_IDS,
    MY_ADDRESS,
    SERVICE_CATEGORY_ID,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_TYPE_ID,
    SERVICE_TYPE_SLUG,
    SPECIALIST_ADDRESS,
} from "../../../../store/order/constant";
import MobileFilterTitle from "./FilterTitle";
import { endpoints } from "../../../../store/directories/directories.api";
import { createOrderState } from "../../../../store/order/createOrder.slice";
import { useActions } from "../../../../hooks/actions";

interface Props {
    isEdit?: boolean;
    entities?: TranslationObject;
    getSpecialist: () => void;
    changeActiveMobileFilter: Dispatch<SetStateAction<string>>;
}

const MobileFilters: FC<Props> = ({
    isEdit = false,
    entities,
    changeActiveMobileFilter,
}) => {
    const { updateCreateOrderField } = useActions();
    const currentLanguage = getCurrentLanguage();
    const [showShortFilters, setShowShortFilters] = useState<boolean>(true);
    const categoryId = isEdit
        ? getItemEditOrder(SERVICE_CATEGORY_ID)
        : getItemCreateOrder(SERVICE_CATEGORY_ID);
    const typeId = isEdit
        ? getItemEditOrder(SERVICE_TYPE_ID)
        : getItemCreateOrder(SERVICE_TYPE_ID);
    const subTypesIds = isEdit
        ? getItemEditOrder(SERVICE_SUB_TYPE_IDS)
        : getItemCreateOrder(SERVICE_SUB_TYPE_IDS);
    const isMyAddress = isEdit
        ? getItemEditOrder(IS_MY_ADDRESS)
        : getItemCreateOrder(IS_MY_ADDRESS);
    const isSpecialistAddress = isEdit
        ? getItemEditOrder(IS_SPECIALIST_ADDRESS)
        : getItemCreateOrder(IS_SPECIALIST_ADDRESS);
    const isRemote = isEdit
        ? getItemEditOrder(IS_REMOTE)
        : getItemCreateOrder(IS_REMOTE);
    const languageIds = isEdit
        ? getItemEditOrder(LANGUAGE_IDS)
        : getItemCreateOrder(LANGUAGE_IDS);
    const filterPages = isEdit
        ? getItemEditOrder(FILTER_PAGES)
        : getItemCreateOrder(FILTER_PAGES);
    const typePath = getItemCreateOrder(SERVICE_TYPE_SLUG);
    const subtypePath = getItemCreateOrder(SERVICE_SUB_TYPE_SLUG);
    const subtypeName = getItemCreateOrder(SERVICE_SUB_TYPE_NAME);

    const [getFilters, { data: filters }] = endpoints.getFilters.useLazyQuery();

    useEffect(() => {
        if (typeId) {
            getFilters({
                id: typeId,
            });
        }
    }, [typeId]);

    let meetPointCount = 0;

    [isMyAddress, isSpecialistAddress, isRemote].forEach(
        (elem) => !!elem && meetPointCount++
    );

    let selectedCount = 0;

    if (categoryId) {
        selectedCount++;
    }
    if (typeId) {
        selectedCount++;
    }
    if (subTypesIds && subTypesIds?.length > 0) {
        selectedCount++;
    }
    if (meetPointCount > 0) {
        selectedCount++;
    }

    if (languageIds && languageIds?.length > 0) {
        selectedCount++;
    }

    filterPages?.forEach(
        (elem) =>
            elem?.service_type_filter_value_ids?.length > 0 && selectedCount++
    );

    if (showShortFilters) {
        return (
            <button
                className="toggle-filter"
                aria-label={entities?.order_filters?.value}
                type="button"
                onClick={() => setShowShortFilters(false)}
            >
                <div>
                    {entities?.order_filters?.value}
                    <b className="filter__star">*</b>
                </div>
                <Icon type="settings"/>
                <span>{selectedCount}</span>
            </button>
        );
    }

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) =>
        updateItemCreateOrder(
            {
                name: name,
                value: value,
            },
            updateCreateOrderField
        );

    const handleClearFilters = () => {
        const clearFilterPages = [];
        filters?.forEach((filter) =>
            clearFilterPages.push({
                service_type_filter_id: filter?.id,
                service_type_filter_value_ids: [],
            })
        );

        [
            { name: IS_MY_ADDRESS, value: false },
            { name: IS_REMOTE, value: false },
            { name: IS_SPECIALIST_ADDRESS, value: false },
            { name: MY_ADDRESS, value: null },
            { name: SPECIALIST_ADDRESS, value: null },
            { name: FILTER_PAGES, value: null },
            { name: "language_ids", value: [] },
        ]?.forEach((elem) =>
            updateField({ name: elem.name, value: elem.value })
        );
    };

    return (
        <div
            className={classNames("filter__block", {
                "is-open": !showShortFilters,
            })}
        >
            <button
                className="filter__subtitle"
                type="button"
                onClick={() => setShowShortFilters(true)}
            >
                {entities?.order_filter?.value || ""}
                <Icon type="arrow-left-pagination" />
            </button>
            <div className="filter__wrap-block mobile">
                {/*{!typePath && (*/}
                {/*    <CategoryItem*/}
                {/*        currentLanguage={currentLanguage}*/}
                {/*        isEdit={isEdit}*/}
                {/*        entities={entities}*/}
                {/*    />*/}
                {/*)}*/}
                {!subtypePath && (
                    <TypeItem
                        currentLanguage={currentLanguage}
                        isEdit={isEdit}
                        entities={entities}
                    />
                )}
                {typePath && subtypePath && (
                    <SubTypeItem
                        currentLanguage={currentLanguage}
                        isEdit={isEdit}
                        entities={entities}
                    />
                )}
            </div>
            <div className="filter__btn-subtitles">
                <MobileFilterTitle
                    title={entities?.common_meeting_place?.value || ""}
                    count={meetPointCount}
                    onClick={() => changeActiveMobileFilter("meeting_place")}
                />
                <MobileFilterTitle
                    title={entities?.registration_languages?.value || ""}
                    count={Array.isArray(languageIds) ? languageIds?.length : 0}
                    onClick={() => changeActiveMobileFilter("languages")}
                />
                {typeId &&
                    filters?.map((filter) => {
                        const currentFilter = filterPages?.find(
                            (filterPage) =>
                                filterPage?.service_type_filter_id ===
                                filter?.id
                        );

                        const count =
                            currentFilter?.service_type_filter_value_ids
                                ?.length || 0;

                        return (
                            <MobileFilterTitle
                                key={filter?.id + "mobile_title_filter"}
                                title={filter.name}
                                count={count}
                                onClick={() =>
                                    changeActiveMobileFilter(String(filter?.id))
                                }
                            />
                        );
                    })}
                <div className="filter__btns">
                    <button
                        className="btn btn btn--rounded btn--transparent filter__btn filter__btn--cancel"
                        type="submit"
                        onClick={() => setShowShortFilters(true)}
                    >
                        {entities
                            ?.common_continue_button
                            ?.value || ""}
                    </button>
                    <button
                        className="btn btn btn--rounded btn--transparent filter__btn filter__btn--cancel"
                        type="button"
                        onClick={handleClearFilters}
                    >
                        {entities?.order_reset_filters?.value || ""}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default MobileFilters;
