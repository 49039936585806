import React, {useEffect, useState} from "react";
import Form from "../../components/Form/Form";
import {toArray} from "../../lib/converters";
import {OrderStepsProps} from "./index";
import {useNavigate, useParams} from "react-router-dom";
import {
    CUSTOM_SERVICE_SUB_TYPE,
    IS_INDIVIDUAL,
    SERVICE_CATEGORY_ID,
    SERVICE_CATEGORY_NAME,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_TYPE_ID,
    SERVICE_TYPE_NAME,
    SPECIALIST_IDS,
} from "../../store/order/constant";
import SelectItemToInput from "../../components/SelectItemToInput";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import {createOrderState} from "../../store/order/createOrder.slice";
import {useActions} from "../../hooks/actions";
import {endpoints as userEndpoints} from "../../store/user/user.api";
import {SubTypesPayload} from "../../models/directoryModel";
import Advertising from "../../components/Advertising";
import CancelOrderButton from "./Specialists/CancelOrderButton";
import {scrollTop} from "../../untils";
import Breadcrumbs from "../../components/Breadcrumbs";
import {useGetUrlCategories} from "../../hooks/useGetUrlCategories";
import {useGetUrlSubtypes} from "../../hooks/useGetUrlSubtypes";
import {endpoints} from "../../store/directories/directories.api";

interface Props extends OrderStepsProps {
    hasFilters: boolean;
    isIndividualOrder: boolean;
}

const SelectServiceSubTypes: React.FC<Props> = ({
                                                    currentLanguage,
                                                    entities,
                                                    onSubmit,
                                                    error,
                                                    onSuccess,
                                                    hasFilters,
                                                    isSuccess,
                                                    isPrivate,
                                                    isIndividualOrder,
                                                    profile,
                                                }) => {
    const {category, type: pathType, subtype: pathSubtype} = useParams();
    const subtypes = getItemCreateOrder(SERVICE_SUB_TYPE_IDS) || [];
    const [nextStep, setNextStep] = useState<string>(
        !pathType
            ? `${category}/list-of-specialists/`
            : subtypes.length === 0
                ? `${category}/${pathType}/list-of-specialists/`
                : `${category}/${pathType}/subtype/`
    );
    const navigate = useNavigate();
    const {updateCreateOrderField} = useActions();
    const [getServiceTypes, {data: serviceTypes}] =
        endpoints.getServiceTypes.useLazyQuery();
    const activeCategory = getItemCreateOrder(SERVICE_CATEGORY_ID);
    const custom = getItemCreateOrder(CUSTOM_SERVICE_SUB_TYPE);
    const activeSubTypes = subtypes?.length ? subtypes : custom || "";
    const [isNewData, setIsNewData] = useState(true);
    const activeType = getItemCreateOrder(SERVICE_TYPE_ID);
    const specialistIds = getItemCreateOrder(SPECIALIST_IDS);
    const typeName = getItemCreateOrder(SERVICE_TYPE_NAME);
    const categoryName = getItemCreateOrder(SERVICE_CATEGORY_NAME);
    const isIndividual = getItemCreateOrder(IS_INDIVIDUAL) || false;
    const [items, setItems] = useState<SubTypesPayload[]>([]);
    const [foundItems, setFoundItems] = useState<SubTypesPayload[] | null>(null);
    const [individualList, setIndividualList] = useState<SubTypesPayload[] | null>(null);
    const [getSpecialist, {data: specialist}] =
        userEndpoints.getSpecialistDetail.useLazyQuery();
    useGetUrlCategories(currentLanguage, profile);
    const serviceSubTypes = useGetUrlSubtypes();
    const [serviceTypePromoText, setServiceTypePromoText] = useState('');
    const updateField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
        updateItemCreateOrder(
            {
                name,
                value
            },
            updateCreateOrderField
        );
    };

    useEffect(() => {
        if (activeCategory) {
            getServiceTypes({
                id: activeCategory,
            });
        }
    }, [activeCategory]);

    useEffect(() => {
        if (!serviceTypes?.length) return;

        const slugType = pathType?.replace(/^type-/gi, '') || '';
        const selectedType = serviceTypes?.find(item => item.slug === slugType);

        if (selectedType?.id) {
            setServiceTypePromoText(selectedType?.promo_text)
        }
    }, [serviceTypes])


    useEffect(() => {
        localStorage.setItem('page', 1);
        if (pathSubtype && pathSubtype !== 'subtype' && !custom) {
            setNextStep(`${category}/${pathType}/${pathSubtype}/`);
        }
    }, [])

    useEffect(() => {
        if (specialistIds?.length && isIndividualOrder) {
            getSpecialist({id: specialistIds[0]});
        }
    }, [specialistIds]);

    useEffect(() => {
        if (serviceSubTypes?.length) {
            let items =
                serviceSubTypes?.filter((serviceType) =>
                    Array.isArray(activeSubTypes)
                        ? activeSubTypes.indexOf(serviceType.id) === -1
                        : serviceType
                ) || [];

            if (isIndividualOrder && specialist) {
                const services =
                    specialist?.services.filter(
                        (elem) => elem?.service_type?.id === activeType
                    ) || [];
                items = items?.filter((elem) =>
                    services?.find(
                        (item) => item?.service_sub_types?.find(subType => subType?.service_sub_type?.id === elem?.id)
                    )
                );
                setNextStep(`${category}/`);
            }
            setItems(items || []);

            setFoundItems(foundItems !== null ? foundItems : items);

            setIndividualList(individualList?.length ? individualList : foundItems !== null ? foundItems : items);
        }
        const item = serviceSubTypes?.find(el => el.id === activeSubTypes[0])
        updateField<typeof SERVICE_SUB_TYPE_NAME>(SERVICE_SUB_TYPE_NAME, activeSubTypes?.length === 1 ? item?.name : null);
    }, [serviceSubTypes, specialist, subtypes, activeType]);

    const list = serviceSubTypes || [];

    const handleClick = (id: number, slug: string) => {
        let slugVal = slug;
        const newItems =
            Array.isArray(activeSubTypes) && activeSubTypes.includes(id)
                ? activeSubTypes.filter((elem) => elem !== id) || []
                : typeof activeSubTypes === "string"
                    ? [id]
                    : activeSubTypes?.concat(id) || [];

        updateField<typeof SERVICE_SUB_TYPE_IDS>(SERVICE_SUB_TYPE_IDS, newItems?.length < 1 ? [] : newItems);
        updateField<typeof SERVICE_SUB_TYPE_SLUG>(SERVICE_SUB_TYPE_SLUG, slugVal ? `subtype-${slugVal}` : 'subtype');

        const subtypesList = isIndividualOrder ? individualList : serviceSubTypes;

        const newFoundItems =
            activeType === id
                ? serviceSubTypes
                : subtypesList?.filter(
                (elem: SubTypesPayload) => !newItems.includes(elem?.id)
            ) || [];

        setFoundItems(newFoundItems?.length ? newFoundItems : null);

        if (!slug && newItems?.length === 1) {
            const selectedSubtype = serviceSubTypes?.find(item => item.id === newItems[0]);
            slugVal = selectedSubtype?.slug || '';
        }

        const nextStepPath = newItems?.length > 1
            ? 'subtype'
            : (slugVal ? `subtype-${slugVal}` : 'list-of-specialists');
        setNextStep(`${category}/${pathType}/${nextStepPath}/`);
    };

    const handleChange = (value: string) => {
        updateField<typeof SERVICE_SUB_TYPE_IDS>(SERVICE_SUB_TYPE_IDS, []);
        updateField<typeof CUSTOM_SERVICE_SUB_TYPE>(CUSTOM_SERVICE_SUB_TYPE, value);
        updateField<typeof SERVICE_SUB_TYPE_SLUG>(SERVICE_SUB_TYPE_SLUG, 'subtype');

        const filtered = items?.filter((item) => {
            const subStr = value.toLocaleLowerCase();

            return (
                item.name.toLocaleLowerCase().includes(subStr) ||
                item?.keywords?.toLocaleLowerCase()?.includes(subStr)
            );
        });

        setFoundItems(value && filtered?.length ? filtered : items);

        if (value !== "") {
            setNextStep(`${category}/${pathType}/${
                getItemCreateOrder(SERVICE_SUB_TYPE_IDS)?.length === 0 ? `list-of-specialists` : `subtype`}/`);
        }
    };

    const _onSuccess = () => {
        if (isSuccess && !isNewData) {
            if (hasFilters) {
                if (!isPrivate || (isPrivate && isIndividualOrder)) {
                    return onSuccess("filters");
                }
            }
            onSuccess(
                isPrivate && !isIndividualOrder ? nextStep : "meeting-point"
            );
        }
    };

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const _onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        setIsNewData(false);
        if (hasFilters && !isPrivate) {
            return onSubmit(e, "filters");
        }
        onSubmit(
            e,
            isPrivate && !isIndividualOrder
                ? nextStep
                : "meeting-point"
        );
    };

    const back = () => {
        navigate(`/${currentLanguage}/create-order/${category}/`);
        scrollTop();
    };

    const updateOrderField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
        updateField(
            name,
            value,
        );
    }

    const skip = () => {
        updateOrderField<typeof SERVICE_SUB_TYPE_IDS>(SERVICE_SUB_TYPE_IDS, []);
        updateOrderField<typeof SERVICE_SUB_TYPE_NAME>(SERVICE_SUB_TYPE_NAME, null);
        updateOrderField<typeof SERVICE_SUB_TYPE_SLUG>(SERVICE_SUB_TYPE_SLUG, null);

        navigate(`/${currentLanguage}/create-order/${category}/${pathType}/list-of-specialists/`);
        scrollTop();
    };

    const crumbs = [
        {
            name: categoryName || entities?.service_task?.value,
            link: `create-order/${category}/`
        },
        {
            active: true,
            name: typeName || entities?.type_of_service_2?.value,
        }
    ]

    return (
        <>
            <h1 className="visually-hidden">
                {entities?.order_select_service_type_title?.value}
            </h1>
            <div className="inner-container">
                <div className="new-order__wrapper">
                    <Breadcrumbs crumbs={crumbs}/>
                    <div className="new-order__header">
                        <h2 className="title title--f46">
                            {
                                typeName
                                    ? entities?.select_services_type_direction_title?.value?.replace(/:type/g, typeName)
                                    : entities?.order_select_service_type_title?.value
                            }
                        </h2>
                        <CancelOrderButton profile={profile} entities={entities}/>
                        <p className="subtitle">
                            {serviceTypePromoText ? serviceTypePromoText : entities?.order_select_service_sub_type_text?.value}
                        </p>
                        <p className="subtitle">
                            {entities?.service_type_selection_prompt_text?.value}
                        </p>
                    </div>
                    <div className="new-order__form form">
                        <Form
                            onSubmit={(e: React.FormEvent<HTMLInputElement>) =>
                                _onSubmit(e)
                            }
                            entities={entities}
                            isSuccess={isSuccess}
                        >
                            {(invalidFields, error) => {
                                const validationError =
                                    invalidFields &&
                                    error &&
                                    error?.errors["service_category_id"] &&
                                    toArray(error?.errors["service_category_id"]).join(`, `);
                                return (
                                    <>
                                        <div className="new-order-start">
                                            <SelectItemToInput
                                                list={list}
                                                currentItems={activeSubTypes}
                                                deleteItem={handleClick}
                                                items={foundItems || []}
                                                onSelect={handleClick}
                                                error={error}
                                                validationError={validationError}
                                                maxLength={255}
                                                // hiddenCloseButton={isIndividualOrder}
                                                withCustomValue
                                                isBig
                                                isMultiple
                                                onChange={handleChange}
                                                categorySlug={category}
                                                typeSlug={pathType}
                                                isSub={true}
                                            />
                                        </div>
                                        <div className="form__row form__controls">
                                            <CancelOrderButton
                                                profile={profile}
                                                entities={entities}
                                            />
                                            <button
                                                className="btn form__back-link btn--transparent"
                                                type="button"
                                                onClick={back}
                                            >{entities?.common_back_button?.value}</button>
                                            <button
                                                className="btn btn--transparent btn--bg-blue"
                                                type="submit"
                                                disabled={!activeSubTypes && isPrivate}
                                            >
                                                {entities?.login_continue_button?.value}
                                            </button>
                                            {isPrivate && !isIndividual && (
                                                <button
                                                    className="btn form__back-link btn--skip"
                                                    type="button"
                                                    onClick={skip}
                                                >
                                                    {
                                                        entities
                                                            ?.skip_type_and_subtype_step
                                                            ?.value
                                                    }
                                                </button>
                                            )}
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
                <ul className="advertising-list create-order">
                    <Advertising countCards={5} entities={entities} isHorizontal={true}/>
                </ul>
            </div>
        </>
    );
};

export default SelectServiceSubTypes;
