import React from "react";
import { useNavigate } from "react-router-dom";
import {
    getCurrentLanguage,
    isCustomerUrl,
} from "../../store/directories/selectors";
import { TranslationObject } from "../../models/translationModal";
import { updateItemCreateOrder } from "../../store/order/selectors";
import { IS_PRIVATE } from "../../store/order/constant";
import { useActions } from "../../hooks/actions";
import { getItemStore } from "../../untils";
import { createOrderState } from "../../store/order/createOrder.slice";
import { ProfilePayload } from "../../models/userModels";
import LogoutModalButton from "../../components/LogoutModalButton/LogoutModalButton";

const SubMenu: React.FC<{
    entities?: TranslationObject;
    profile?: ProfilePayload;
}> = ({ entities, profile }) => {
    const { updateCreateOrderField, clearCreateOrderFields } = useActions();
    const currentLanguage = getCurrentLanguage();
    const isCustomerOnUrl = isCustomerUrl();
    const navigate = useNavigate();
    const isSpecialist = profile?.user_role
        === 'specialist'

    const updateOrderField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    const handleClick = async (isPrivate?: boolean) => {
        if (isSpecialist) {
            return;
        }

        const is_private = getItemStore(IS_PRIVATE);
        if (isPrivate || (is_private !== isPrivate)) {
            await localStorage.clear();
            clearCreateOrderFields();
        }
        updateOrderField({
            name: IS_PRIVATE,
            value: isPrivate || false,
        });
        await navigate(`/${currentLanguage}/create-order`, {
            replace: true,
        });
    };

    if (isCustomerOnUrl) {
        return (
            <div className="submenu">
                <div className="inner-container">
                    <LogoutModalButton 
                        handleClick={handleClick}
                        isSpecialist={isSpecialist}
                        entities={entities}
                        classes={["btn", "submenu__link"]}
                        text={entities?.create_order_link_value?.value}
                    />

                    <LogoutModalButton 
                        handleClick={() => handleClick(true)}
                        isSpecialist={isSpecialist}
                        entities={entities}
                        classes={["btn", "submenu__link", "submenu__link--green"]}
                        text={entities?.create_private_order_link_value?.value}
                    />
                </div>
            </div>
        );
    }

    return null;
};

export default SubMenu;
