import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../Icon";
import {
    IS_CUSTOMER_ADDRESS,
    IS_MY_ADDRESS, IS_PRIVATE,
    IS_REMOTE, SERVICE_CATEGORY_ID,
    SERVICE_CATEGORY_NAME,
    SERVICE_CATEGORY_SLUG,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_TYPE_ID,
    SERVICE_TYPE_NAME,
    SERVICE_TYPE_SLUG
} from "../../store/order/constant";
import classNames from "classnames";
import { TranslationObject } from "../../models/translationModal";
import {ProfilePayload, Service, ServiceSubType} from "../../models/userModels";
import PhotoSlider from "./PhotoSlider";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { createOrderState } from "../../store/order/createOrder.slice";
import { updateItemCreateOrder } from "../../store/order/selectors";
import { useActions } from "../../hooks/actions";

const ServiceTypeCard: React.FC<{
    serviceType: Service;
    entities?: TranslationObject;
    serviceTypeTranslate?: Service | null;
    profile?: ProfilePayload
}> = ({ serviceType, entities, serviceTypeTranslate, profile }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const currentLanguage = getCurrentLanguage();
    const { updateCreateOrderField } = useActions();
    const points = {
        [IS_REMOTE]: `${entities?.common_remotely?.value}`,
        [IS_MY_ADDRESS]: `${entities?.common_specialist_adress?.value}`,
        [IS_CUSTOMER_ADDRESS]: `${entities?.common_customer_adress?.value}`,
    };
    const title = serviceType?.service_type?.name + ': ' + serviceType?.service_sub_types?.map(service => service?.service_sub_type?.name).filter(name => name).join(", ");
    const isSpecialistRole = profile?.user_role === 'specialist';
    const updateOrderField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
        updateField({
            name,
            value,
        });
    };

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    const handleClick = (category, type, subtype) => {
        updateOrderField<typeof SERVICE_CATEGORY_ID>(SERVICE_CATEGORY_ID, category.id);
        updateOrderField<typeof SERVICE_CATEGORY_NAME>(SERVICE_CATEGORY_NAME, category.name);
        updateOrderField<typeof SERVICE_CATEGORY_SLUG>(SERVICE_CATEGORY_SLUG, `category-${category.slug}`);
        updateOrderField<typeof SERVICE_TYPE_ID>(SERVICE_TYPE_ID, type.id);
        updateOrderField<typeof SERVICE_TYPE_NAME>(SERVICE_TYPE_NAME, type.name);
        updateOrderField<typeof SERVICE_TYPE_SLUG>(SERVICE_TYPE_SLUG, `type-${type.slug}`);
        if (subtype) {
            updateOrderField<typeof SERVICE_SUB_TYPE_IDS>(SERVICE_SUB_TYPE_IDS, [subtype?.id]);
            updateOrderField<typeof SERVICE_SUB_TYPE_NAME>(SERVICE_SUB_TYPE_NAME, subtype?.name);
            updateOrderField<typeof SERVICE_SUB_TYPE_SLUG>(SERVICE_SUB_TYPE_SLUG, `subtype-${subtype?.slug}`);
        }
        updateOrderField<typeof IS_PRIVATE>(IS_PRIVATE, true);

        navigate(subtype
            ? `/${currentLanguage}/create-order/category-${category?.slug}/type-${type?.slug}/subtype-${subtype?.slug}/`
            : `/${currentLanguage}/create-order/category-${category?.slug}/type-${type?.slug}/list-of-specialists/`
        );
    };

    return (
        <div
            className={classNames(
                "specialist-page__dropdown grey-card dropdown",
                {
                    "is-open": isOpen,
                }
            )}
        >
            <div className="dropdown__header">
                <h3 className="dropdown__title title title--f32">
                    {serviceType?.service_type?.name ? (
                        !isSpecialistRole ? <>
                            <a
                                className="link"
                                style={{marginRight: '5px', color: '#17171b'}}
                                href={`/${currentLanguage}/create-order/category-${serviceType.service_category?.slug}/type-${serviceType.service_type?.slug}/list-of-specialists/`}
                                target="_blank"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClick(
                                        serviceType.service_category,
                                        serviceType.service_type,
                                        null
                                    );
                                }}
                            >
                                {serviceType?.service_type?.name}
                            </a>
                        </> : <>
                            <div className="popover popover--grey specialist popover--specialist-link">
                                <a
                                    className="prof-services__link-more link link--underline"
                                    style={{marginRight: '5px', color: '#17171b'}}
                                >
                                    {serviceType?.service_type?.name}
                                </a>
                                <span className="popover__tip">
                                    {entities?.profile_logout_specialist_text?.value}
                                </span>
                            </div>
                        </>
                    ) : (
                        serviceType?.custom_service_typ
                    )}
                </h3>
                <button
                    className="dropdown__btn btn btn--transparent btn--nb"
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <Icon
                        type={isOpen ? "arrow_up" : "arrow_down"}
                        width={20}
                        height={20}
                    />
                </button>
            </div>
            <div className="dropdown__content">
                <p className="dropdown__item dropdown__sub subtitle subtitle--sm"></p>
                <p className="dropdown__item text color-grey" style={{margin: '0 0 12px 0'}}>
                    {serviceTypeTranslate?.description ||
                        serviceType?.description}
                </p>
                <h4 className="dropdown__item title title--h4">
                    {entities?.common_meeting_place?.value}
                </h4>
                <div className="dropdown__item text">
                    {(serviceType?.is_remote) && (
                        <p>
                            <b>{points["is_remote"]}</b>
                        </p>
                    )}
                    {(serviceType?.specialist_address) && (
                        <p>
                            <b>{points["is_my_address"]}: {serviceType?.specialist_address?.value}</b>
                        </p>
                    )}
                    {(serviceType?.customer_address) && (
                        <p>
                            <b>{points["is_customer_address"]}: {serviceType?.customer_address?.value}, {entities?.in_distance_of?.value.replace(":radius", serviceType?.customer_address?.radius)}</b>
                        </p>
                    )}
                </div>
                <h4 className="dropdown__item title title--h4">
                    {entities?.list_of_services_title?.value}
                </h4>
                <div className="dropdown__item details subtypes">
                    {serviceType?.service_sub_types?.map(
                        (serviceSubType: ServiceSubType, key: number) => (
                            <dl key={key + serviceSubType?.id}>
                                <dt>
                                    {serviceSubType?.service_sub_type?.name ? (
                                        <h5 className="dropdown__item title title--h5">
                                            {serviceSubType?.service_sub_type?.name ? (
                                                !isSpecialistRole ? <>
                                                    <a
                                                        className="link"
                                                        style={{marginRight: '5px'}}
                                                        href={`/${currentLanguage}/create-order/category-${serviceType.service_category?.slug}/type-${serviceType.service_type?.slug}/subtype-${serviceSubType?.service_sub_type.slug}/`}
                                                        target="_blank"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleClick(
                                                                serviceType.service_category,
                                                                serviceType.service_type,
                                                                serviceSubType?.service_sub_type
                                                            );
                                                        }}
                                                    >
                                                        {serviceSubType?.service_sub_type?.name}
                                                    </a>
                                                </> : <>
                                                    <div
                                                        className="popover popover--grey specialist popover--specialist-link">
                                                        <a className="prof-services__link-more link link--underline">
                                                            {serviceSubType?.service_sub_type?.name}
                                                        </a>
                                                        <span className="popover__tip">
                                                            {entities?.profile_logout_specialist_text?.value}
                                                        </span>
                                                    </div>
                                                </>
                                            ) : (
                                                serviceSubType?.custom_service_sub_type
                                            )}
                                        </h5>
                                    ) : (
                                        serviceSubType?.custom_service_sub_type
                                    )}
                                    {(serviceTypeTranslate?.service_sub_types?.find(
                                        (el) => el?.id === serviceSubType?.id
                                    )?.description || serviceSubType?.description) && (
                                        <p className="details__text text color-grey" style={{margin: '0'}}>
                                            {serviceTypeTranslate?.service_sub_types?.find(
                                                (el) => el?.id === serviceSubType?.id
                                            )?.description || serviceSubType?.description}
                                        </p>
                                    )}
                                </dt>
                                <dd>
                                    <h5 className="dropdown__item title title--h5">
                                        {serviceTypeTranslate?.service_sub_types?.find(
                                            (el) => el?.id === serviceSubType?.id
                                        )?.price || serviceSubType?.price}
                                    </h5>
                                </dd>
                            </dl>
                        )
                    )}
                </div>
                {serviceType?.photos?.length > 0 && (
                    <PhotoSlider
                        photos={serviceType?.photos}
                        entities={entities}
                        title={title}
                    />
                )}
            </div>
        </div>
    );
};

export default ServiceTypeCard;
