import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link, useLocation } from "react-router-dom";
import { getCookie, setCookie } from "../../untils/cookies/index";
import { useActions } from "../../hooks/actions";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { getUserRole } from "../../store/user/selectors";
import { TranslationObject } from "../../models/translationModal";

interface Props {
    entities?: TranslationObject;
    isFirefox?: boolean;
}

const CookieBlock: React.FC<Props> = ({ entities, isFirefox }) => {
    const container = document.getElementById('root') || document.body;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const currentLanguage = getCurrentLanguage();
    const { updateAgreeWithCookie } = useActions();
    const agreeWithCookie = "agreeWithCookie";
    const userRole = getUserRole();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const queryDisableCookies = queryParams.get('disable_cookies');

        if (queryDisableCookies === 'true') {
            onChangeCookie(false);
            // Удалить квери из строки
            queryParams.delete('disable_cookies');
            // window.history.replaceState(null, '', `${window.location.pathname}?${queryParams}`);
            return;
        }

        if (getCookie(agreeWithCookie)) {
            updateAgreeWithCookie(
                getCookie(agreeWithCookie) === "true" ? true : false
            );
            return;
        }
        setIsOpen(true);
    }, []);

    const onChangeCookie = (bool: boolean) => {
        setCookie(agreeWithCookie, String(bool));
        updateAgreeWithCookie(bool);
        setIsOpen(false);
    };

    const url =
        userRole !== "guest"
            ? `${currentLanguage}/${userRole}/cookies-policy`
            : `${currentLanguage}/cookies-policy`;

    const jsxCookies = () =>
        <div className="cookies">
            <div className="cookies__container">
                <p className="cookies__text">
                    {entities?.cookies_text?.value}
                    <Link to={url} className="link">
                        {entities?.cookies_link_value?.value}
                    </Link>
                </p>
                <button
                    className="btn btn--white btn--rounded cookies__btn"
                    type="button"
                    onClick={() => onChangeCookie(false)}
                >
                    {entities?.cookies_button_disagree_value?.value}
                </button>
                <button
                    className="btn btn--rounded cookies__btn"
                    type="button"
                    onClick={() => onChangeCookie(true)}
                >
                    {entities?.cookies_button_agree_value?.value}
                </button>
            </div>
        </div>

    return isOpen
        ? (isFirefox ? ReactDOM.createPortal(jsxCookies(), container) : jsxCookies())
        : null;
};

export default CookieBlock;
