import React, { useState } from "react";
import RadioInput from "../Form/RadioInput";
import Modal from "../Modal";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { TranslationObject } from "../../models/translationModal";
import { Category } from "../../models/userModels";
import {
    IS_INDIVIDUAL,
    IS_PRIVATE,
    SERVICE_CATEGORY_ID, SERVICE_CATEGORY_NAME,
    SERVICE_CATEGORY_SLUG,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_TYPE_SLUG,
    SPECIALIST_ADDRESS,
    SPECIALIST_IDS,
    SPECIALIST_NAME
} from "../../store/order/constant";
import {createOrderState} from "../../store/order/createOrder.slice";
import {
    clearOrderDataInLocalStorage,
    getItemCreateOrder
} from "../../store/order/selectors";
import { LocationData } from "../../models/orderModel";
import { useActions } from "../../hooks/actions";
import { getOrderCategoriesFields } from "../../untils";

interface Props {
    entities?: TranslationObject;
    visible: boolean;
    close: (isOrder?: boolean) => void;
    id?: number;
    categories?: Category[];
    updateField: <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => void;
    profileId?: number;
    specialistName?: string;
    cat?: number;
    cat_slug?: string;
    specialistAddress?: LocationData,
    isIndividual?: boolean,
    isCatSelect?: boolean
    services?: Service[];
}

const ModalSelectCategory: React.FC<Props> = ({
    entities,
    visible,
    close,
    id,
    categories,
    updateField,
    profileId,
    specialistName,
    cat,
    cat_slug,
    specialistAddress,
    isIndividual = true,
    isCatSelect = false,
    services = []
}) => {
    const navigate = useNavigate();
    const currentLanguage = getCurrentLanguage();
    const { clearCreateOrderFields } = useActions();
    const [category, setCategory] = useState<Category | null>(null);
    const subtypePath = getItemCreateOrder(SERVICE_SUB_TYPE_SLUG) || null;
    const typePath = getItemCreateOrder(SERVICE_TYPE_SLUG) || null;
    const isPrivate = getItemCreateOrder(IS_PRIVATE) || false;

    const handleClick = async () => {
        clearOrderDataInLocalStorage(false, []);
        clearCreateOrderFields();

        if (!category) return;

        const activeStep = cat
            ? typePath
                ? subtypePath
                    ? `category-${cat_slug}/${typePath}/${subtypePath}/`
                    : `category-${cat_slug}/${typePath}/`
                : `category-${cat_slug}/${typePath}/`
            : isCatSelect
                ? `category-${category.slug}`
                : `category-${cat_slug}`;

        const items: {
            name: keyof createOrderState;
            value: createOrderState[keyof createOrderState];
        }[] = [
            { name: IS_PRIVATE, value: true },
            { name: "userId", value: profileId || null },
            {
                name: SERVICE_CATEGORY_ID,
                value: cat ? cat : category.id,
            },
            {
                name: SERVICE_CATEGORY_NAME,
                value: `${category?.name}`,
            },
            {
                name: SERVICE_CATEGORY_SLUG,
                value: cat ? `category-${cat_slug}` : `category-${category.slug}`,
            },
            { name: "activeStep", value: activeStep },
            { name: IS_INDIVIDUAL, value: isIndividual },
            { name: 'page', value: '' },
        ];

        if (id) {
            items.push(
                { name: SPECIALIST_IDS, value: [id] },
                { name: SPECIALIST_NAME, value: specialistName }
            )
        }

        if (cat) {
            items.push(
                { name: SERVICE_CATEGORY_ID, value: cat },
                { name: SERVICE_CATEGORY_SLUG, value: cat_slug },
                { name: SERVICE_SUB_TYPE_IDS, value: [] },
                { name: SERVICE_SUB_TYPE_SLUG, value: null }
            )
        } else {
            const orderCatFields = getOrderCategoriesFields(services, category.id);
            items.push(...orderCatFields)
        }

        if (specialistAddress) {
            items.push({
                name: SPECIALIST_ADDRESS,
                value: specialistAddress
            }, {
                name: 'isAdvertising',
                value: true
            })
        }

        items.forEach((elem) =>
            updateField({
                name: elem.name,
                value: elem.value,
            })
        );

        closeModal(true);

        if (cat) {
            return navigate(typePath
                ? `/${currentLanguage}/create-order/category-${cat_slug}/${typePath}`
                : `/${currentLanguage}/create-order/category-${cat_slug}/`
            );
        }

        const serviceCategorySlugObject = items.find(item => item.name === "service_category_slug");
        const serviceCategorySlug = serviceCategorySlugObject ? serviceCategorySlugObject.value : null;
        const serviceTypeSlugObject = items.find(item => item.name === "service_type_slug");
        const serviceTypeSlug = serviceTypeSlugObject ? serviceTypeSlugObject.value : null;
        const serviceSubTypeSlugObject = items.find(item => item.name === "service_sub_type_slug");
        const serviceSubTypeSlug = serviceSubTypeSlugObject ? serviceSubTypeSlugObject.value : null;

        navigate(serviceCategorySlug
            ? serviceTypeSlug
                ? serviceSubTypeSlug
                    ? isPrivate || isIndividual
                        ? `/${currentLanguage}/create-order/meeting-point`
                        : `/${currentLanguage}/create-order/${serviceCategorySlug}/${serviceTypeSlug}/${serviceSubTypeSlug}/`
                    : `/${currentLanguage}/create-order/${serviceCategorySlug}/${serviceTypeSlug}/`
                : `/${currentLanguage}/create-order/${serviceCategorySlug}/`
            : `/${currentLanguage}/create-order/`
        );
    };

    const closeModal = (isOrder = false) => {
        close(isOrder)
    }

    return (
        <Modal
            title={cat ? entities?.order_select_one_type?.value : entities?.order_category_offer?.value}
            visible={visible}
            onClose={closeModal}
        >
            <>
                {categories?.map((item: Category, key: number) => (
                    <RadioInput
                        key={key}
                        label={item?.name}
                        checked={item?.id == category?.id}
                        onClick={() => setCategory(item)}
                    />
                ))}
                <div className="form__row form__controls form__controls--right">
                    <button
                        className="btn form__control form__back-link btn--transparent"
                        type="button"
                        onClick={closeModal}
                    >
                        {entities?.common_cancel_button?.value}
                    </button>
                    <button
                        className="btn form__control btn--transparent btn--bg-green"
                        onClick={handleClick}
                        type="button"
                    >
                        {entities?.common_save_button?.value}
                    </button>
                </div>
            </>
        </Modal>
    );
};

export default ModalSelectCategory;
