import React, { ReactNode, useEffect } from "react";
import { getTkn } from "../../store/user/selectors";
import { useActions } from "../../hooks/actions";
import { Navigate } from "react-router-dom";
import { getCurrentLanguage, getPath } from "../../store/directories/selectors";
import Loader from "../Loader";
import { ProfilePayload } from "../../models/userModels";

interface Props {
    path: string;
    element: ReactNode;
    profile?: ProfilePayload;
    isLoading: boolean;
}

const suspendedAccessPages = ["profile", "notifications", "orders"];

export const ProtectRoute: React.FC<Props> = ({
    path,
    element,
    profile,
    isLoading,
}) => {
    const token = getTkn();
    const currentLanguage = getCurrentLanguage();
    const { updateUserField } = useActions();

    useEffect(() => {
        if (!token) {
            updateUserField({
                name: "urlAfterAuthorization",
                value: null,
            });
        }
    }, [token]);

    const role = getPath()[1];

    if (isLoading) {
        return <Loader />;
    }

    const pathIsSuspended =
        !profile?.is_suspended ||
        (profile?.is_suspended &&
            suspendedAccessPages.find((el) => path.includes(el)));

    const to = () => {
        let url = `/${currentLanguage}/${role}`;

        if (role === "specialist") {
            if (profile?.id && !profile?.has_password) {
                return `${url}/registration`;
            }
            url = `${url}/auth`;
        }

        if (role === "customer") {
            if (profile?.id && !profile?.registered_at) {
                return `${url}/registration`;
            }
            url = `${url}/auth`;
        }

        return url;
    };

    if (
        (profile?.id && profile?.has_password && currentLanguage && role === "specialist") ||
        (profile?.id && profile?.registered_at && currentLanguage && role === "customer")
    ) {
        if (!pathIsSuspended) {
            return (
                <Navigate
                    to={`/${currentLanguage}/${role}/lk/profile`}
                    key={`:currentLanguage/:role/lk/profile`}
                />
            );
        }
        return <>{element}</>;
    }

    return <Navigate to={to()} key={path} />;
};

export default ProtectRoute;
