import React, {useEffect} from "react";
import Form from "../../components/Form/Form";
import Input from "../../components/Form/Input";
import {getTkn} from "../../store/user/selectors";
import {useActions} from "../../hooks/actions";
import {Link, useNavigate} from "react-router-dom";
import {OrderStepsProps} from "./index";
import {CurrentSpecialistsPayload, ProfilePayload} from "../../models/userModels";
import {
    SERVICE_CATEGORY_ID,
    STATUS,
    TITLE,
    SERVICE_CATEGORY_NAME,
    SERVICE_TYPE_NAME,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_TYPE_SLUG,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_CATEGORY_SLUG,
    IS_PRIVATE,
    FILTER_PAGES,
    IS_INDIVIDUAL,
    IS_REMOTE,
    IS_MY_ADDRESS,
    IS_SPECIALIST_ADDRESS,
    STARTED_AT,
    REPEAT_RANGE,
    REPEAT_DAY,
    ENDED_AT,
    PRICE, DESCRIPTION, SPECIALIST_IDS,
} from "../../store/order/constant";
import {endpoints as orderEndpoints} from "../../store/order/order.api"
import {endpoints} from "../../store/directories/directories.api";
import {createOrderState} from "../../store/order/createOrder.slice";
import {
    getItemCreateOrder, getRanges, getWeekdays,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import Advertising from "../../components/Advertising";
import CancelOrderButton from "./Specialists/CancelOrderButton";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextArea from "../../components/Form/TextArea";
import Avatar from "../../components/Avatar";
import {formatQueryString, scrollTop} from "../../untils";

interface FinishingProps extends OrderStepsProps {
    currentLanguage: string;
    profile?: ProfilePayload;
    isLoading?: boolean;
}

const Finishing: React.FC<FinishingProps> = ({
                                                 entities,
                                                 cancelOrder,
                                                 isSuccess,
                                                 onSubmit,
                                                 error,
                                                 currentLanguage,
                                                 profile,
                                                 isLoading = false,
                                                 back,
                                             }) => {
    const token = getTkn();
    const navigate = useNavigate();
    const {updateUserField, updateCreateOrderField, clearCreateOrderFields} =
        useActions();
    const title = getItemCreateOrder(TITLE);
    const categoryId = getItemCreateOrder(SERVICE_CATEGORY_ID);
    const {data: categories} = endpoints.getServiceCategories.useQuery();
    const isLogin = profile?.id && token;
    const subTypesIdsLength = getItemCreateOrder(SERVICE_SUB_TYPE_IDS)?.length;
    const categoryName = getItemCreateOrder(SERVICE_CATEGORY_NAME);
    const categoryPath = getItemCreateOrder(SERVICE_CATEGORY_SLUG);
    const typeName = getItemCreateOrder(SERVICE_TYPE_NAME);
    const typePath = getItemCreateOrder(SERVICE_TYPE_SLUG);
    const subTypeName = getItemCreateOrder(SERVICE_SUB_TYPE_NAME);
    const subtypePath = getItemCreateOrder(SERVICE_SUB_TYPE_SLUG);
    const isIndividual = getItemCreateOrder(IS_INDIVIDUAL) || false;
    const isPrivate = getItemCreateOrder(IS_PRIVATE) || false;
    const filterPages = getItemCreateOrder(FILTER_PAGES) || [];
    const isRemote = getItemCreateOrder(IS_REMOTE)
    const isMyAdress = getItemCreateOrder(IS_MY_ADDRESS)
    const isSpecialistAdress = getItemCreateOrder(IS_SPECIALIST_ADDRESS);

    const startedAt = getItemCreateOrder(STARTED_AT);
    const endedAt = getItemCreateOrder(ENDED_AT);

    const repeatRange = getItemCreateOrder(REPEAT_RANGE);
    const repeatDay = getItemCreateOrder(REPEAT_DAY);

    const price = getItemCreateOrder(PRICE);

    const description = getItemCreateOrder(DESCRIPTION);

    const [getSpecialists, {data: specialists}] =
        orderEndpoints.getCurrentSpecialists.useLazyQuery();

    const shortListSpecialists = Array.isArray(specialists)
        ? specialists?.slice(0, 3)
        : [];


    const specialistIds = getItemCreateOrder(SPECIALIST_IDS);
    const hasSpecialist = specialistIds && specialistIds?.length > 0;


    useEffect(() => {
        if (specialistIds?.length > 0) {
            getSpecialists({
                params: formatQueryString({
                    specialist_ids: specialistIds,
                }),
            });
        }
    }, [specialistIds]);

    const updateField = <K extends keyof createOrderState>({
                                                               name,
                                                               value,
                                                           }: {
        name: K;
        value: createOrderState[K];
    }) =>
        updateItemCreateOrder(
            {
                name: name,
                value: value,
            },
            updateCreateOrderField
        );

    const login = () => {
        updateUserField({
            name: "urlAfterAuthorization",
            value: `/${currentLanguage}/create-order`,
        });
        navigate(`/${currentLanguage}/customer/auth`);
    };

    const _onSubmit = (
        e?: React.FormEvent<HTMLInputElement>,
        withPublish?: boolean
    ) => {
        if (e) {
            e.preventDefault();
        }
        if (withPublish) {
            updateUserField({
                name: "urlAfterAuthorization",
                value: `/${currentLanguage}/create-order`,
            });

            if (isLogin) {
                updateField({
                    name: STATUS,
                    value: "active",
                });
            } else {
                navigate(`/${currentLanguage}/customer/registration`);
            }
        }
        onSubmit();
    };

    const _onSuccess = () => {
        localStorage.clear();
        clearCreateOrderFields();
        navigate(`/${currentLanguage}/customer/lk/orders`);
    };

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const handleChange = (value: string) => {
        updateField({
            name: TITLE,
            value,
        });
    };
    const _back = () => {
        if (back) {
            back("wishes");
        }
        navigate(`/${currentLanguage}/create-order/wishes`);
    };

    const handleClick = () => {
        if (isLogin) {
            return _onSubmit();
        }
        login();
    };

    const crumbs = [
        {
            name: categoryName || entities?.service_task?.value,
            link: `create-order/${categoryPath}`
        },
        ...(typePath ? [
            {
                name: typeName || entities?.type_of_service_2?.value,
                link: `create-order/${categoryPath}/${typePath}`
            }
        ] : []),
        ...(isPrivate && !isIndividual ? [
            {
                name: subTypeName
                    ? `${subTypeName} - ${entities?.choosing_a_specialist?.value}`
                    : subTypesIdsLength
                        ? `${entities?.subtype_of_service_2?.value} (${subTypesIdsLength}) - ${entities?.choosing_a_specialist?.value}`
                        : entities?.choosing_a_specialist?.value,
                link: typePath || typePath
                    ? `create-order/${categoryPath}/${typePath}/${subtypePath || 'subtype'}`
                    : `create-order/${categoryPath}/list-of-specialists`
            }
        ] : []),
        ...(!isPrivate && filterPages.length > 0 ? [
            {
                name: entities?.service_filter?.value,
                link: 'create-order/filters'
            }
        ] : []),
        {
            name: entities?.common_meeting_place?.value,
            link: 'create-order/meeting-point'
        },
        {
            name: entities?.date_and_time?.value,
            link: 'create-order/when-to-start'
        },
        {
            name: entities?.periodicity?.value,
            link: 'create-order/repeat'
        },
        {
            name: entities?.additionally?.value,
            link: 'create-order/wishes'
        },
        {
            active: true,
            name: entities?.application_name?.value
        }
    ]


    const ranges = getRanges(entities);

    const specialistsLink = `/${currentLanguage}/create-order/selected_specialists`;

    const weekdays = getWeekdays(currentLanguage);
    const renderRepeat = (repeatRange: string) => {

        if (repeatRange === "repeat_week_day" && repeatDay && repeatDay > 0)
            return `${entities?.order_periodicity_every?.value} ${weekdays[repeatDay - 1]}`;
        else if (repeatRange === "repeat_month_day" && repeatDay && repeatDay > 0)
            return `${entities?.order_periodicity_every_month?.value} ${repeatDay} ${entities?.order_periodicity_day?.value}`

        return ranges.find(el => el.name === repeatRange)?.label || 'None'
    }

    const selectedSpecialistsText = `${entities?.order_edit_specialists_your_select?.value} ${specialists?.length}
                                     ${entities?.order_edit_specialists_your_select_count?.value}`

    return (
        <div className="inner-container">
            <div className="new-order__wrapper">
                <Breadcrumbs
                    crumbs={crumbs}
                />
                <div className="new-order__header">
                    <h2 className="title title--f46">
                        {entities?.order_finish_title?.value}
                    </h2>
                    <CancelOrderButton
                        profile={profile}
                        entities={entities}
                    />
                </div>
                <div className="new-order__form form">
                    <Form
                        onSubmit={(e: React.FormEvent<HTMLInputElement>) =>
                            _onSubmit(e, true)
                        }
                        isSuccess={isSuccess}
                        error={error}
                    >
                        {() => (
                            <>
                                <div className="form__row form__row--big-margin">
                                    <fieldset>
                                        <legend>
                                            {
                                                entities
                                                    ?.order_finish_order_name
                                                    ?.value
                                            }
                                        </legend>
                                        <Input
                                            type="text"
                                            placeholder=""
                                            maxLength={50}
                                            value={title}
                                            onChange={handleChange}
                                        />
                                    </fieldset>
                                </div>
                                <div className={"new-order__preview"}>
                                    <p>
                                        <span>{entities?.service_category.value}</span>: {categoryName || entities?.advertisement_no_selected?.value}
                                    </p>
                                    <p>
                                        <span>{entities?.type_of_service_2.value}</span>: {typeName || entities?.advertisement_no_selected?.value}
                                    </p>
                                    <p>
                                        <span>{entities?.subtype_of_service_2.value}</span>: {subTypeName || entities?.advertisement_no_selected?.value}
                                    </p>
                                    <p><span>{entities?.common_meeting_place?.value}</span> : {
                                        isRemote ? entities?.common_remotely?.value
                                            : isMyAdress ? entities?.common_customer_adress?.value
                                                : isSpecialistAdress ? entities?.common_specialist_adress?.value
                                                    : entities?.advertisement_no_selected?.value
                                    }</p>
                                    <p><span>{entities?.order_date?.value}</span>: {entities?.common_from?.value} <u>{startedAt || entities?.advertisement_no_selected?.value}</u> {entities?.common_to?.value} <u>{endedAt || entities?.advertisement_no_selected?.value}</u></p>
                                    <p><span>{entities?.order_periodicity?.value}</span>: {renderRepeat(repeatRange)}</p>
                                    <p>
                                        <span>{entities?.order_additional_data_price?.value}</span>: {price || entities?.advertisement_no_selected?.value}
                                    </p>
                                    <fieldset>
                                        <legend>
                                            {entities?.wishes_and_suggestions?.value}
                                        </legend>
                                        <TextArea
                                            type="text"
                                            maxLength={500}
                                            defaultValue={description || entities?.advertisement_no_selected?.value}
                                            disabled={true}
                                        />
                                    </fieldset>
                                    <div className={"new-order__notice"}>
                                        {isPrivate ? (
                                            <div className={"new-order__specialists"}>
                                                <ul className="output__list">
                                                    {shortListSpecialists?.map(
                                                        (
                                                            specialist: CurrentSpecialistsPayload,
                                                            key: number
                                                        ) => (
                                                            <li className="output__item" key={key}>
                                                                <Avatar
                                                                    withOutPicture
                                                                    width={48}
                                                                    height={48}
                                                                    avatar={specialist?.avatar}
                                                                />
                                                            </li>
                                                        )
                                                    )}
                                                    {hasSpecialist &&
                                                        specialists &&
                                                        specialists?.length > 3 && (
                                                            <li className="output__item output__item--link">
                                                                <a
                                                                    className="link output__link"
                                                                    href="#"
                                                                    aria-label={
                                                                        entities
                                                                            ?.order_show_selected
                                                                            ?.value
                                                                    }
                                                                >
                                                                    +
                                                                    {specialists &&
                                                                        specialists?.length - 3}
                                                                </a>
                                                                <span className="output__quantity">
                                                                    +{specialists?.length - 3}
                                                                </span>
                                                            </li>
                                                        )}
                                                </ul>
                                                <p>{selectedSpecialistsText}</p>
                                                <Link onClick={() => scrollTop()} className={"output__link"}
                                                      to={specialistsLink}>
                                                    <u>{entities?.order_show_selected?.value}</u>
                                                </Link>
                                            </div>
                                        ) : (
                                            <p>{entities?.order_show_for_all?.value}</p>
                                        )}
                                    </div>

                                </div>
                                <div className="new-order__authorization">
                                    {!isLogin && (
                                        <p className="new-order__authorization-heading">
                                            {
                                                entities
                                                    ?.order_finish_order_auth_text
                                                    ?.value
                                            }
                                        </p>
                                    )}
                                    <p className="new-order__authorization-text">
                                        {
                                            entities
                                                ?.order_finish_order_auth_text_placeholder
                                                ?.value
                                        }
                                    </p>
                                </div>

                                <div className="form__row form__controls">
                                    <button
                                        className="btn form__back-link btn--transparent"
                                        type="button"
                                        onClick={_back}
                                    >
                                        {entities?.login_return_button?.value}
                                    </button>
                                    <button
                                        className="btn form__back-link btn--transparent"
                                        type="button"
                                        onClick={handleClick}
                                    >
                                        {isLogin
                                            ? entities?.order_save_as_draft
                                                ?.value
                                            : entities
                                                ?.login_phone_confirmation_enter_button
                                                ?.value}
                                    </button>
                                    <button
                                        className="btn btn--transparent btn--bg-blue"
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        {isLogin
                                            ? entities?.order_save?.value
                                            : entities?.registration_link_value
                                                ?.value}
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </div>
            <ul className="advertising-list create-order">
                <Advertising countCards={5} entities={entities} isHorizontal={true} />
            </ul>
        </div>
    );
};

export default Finishing;
