import React, { useEffect, useState } from "react";
import { Link,  useNavigate,  useParams} from "react-router-dom";
import { endpoints } from "../../../store/directories/directories.api";
import {
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_TYPE_ID
} from "../../../store/order/constant";
import {
    getItemCreateOrder,
    getItemEditOrder,
    updateItemCreateOrder
} from "../../../store/order/selectors";
import { createOrderState } from "../../../store/order/createOrder.slice";
import { useActions } from "../../../hooks/actions";
import { TranslationObject } from "../../../models/translationModal";

const SubTypeItem: React.FC<{
    currentLanguage: string;
    isEdit: boolean;
    entities?: TranslationObject;
}> = ({currentLanguage, isEdit, entities}) => {
    const { updateCreateOrderField } = useActions();
    const { category, type: pathType, subtype: pathSubtype } = useParams();
    const navigate = useNavigate();
    const id = isEdit
        ? getItemEditOrder(SERVICE_SUB_TYPE_IDS)
        : getItemCreateOrder(SERVICE_SUB_TYPE_IDS);
    const activeType = getItemCreateOrder(SERVICE_TYPE_ID);
    const activeSubTypes = getItemCreateOrder(SERVICE_SUB_TYPE_IDS) || [];
    const [getServiceSubTypes, {data: serviceSubTypes}] = endpoints.getServiceSubTypes.useLazyQuery();
    const validActiveSubTypes = Array.isArray(activeSubTypes) ? activeSubTypes : [];
    const currentSubtypes = serviceSubTypes?.filter((item) => validActiveSubTypes.includes(item.id)) || [];
    const otherSubTypes = serviceSubTypes
        ?.filter((item) => !validActiveSubTypes.includes(item.id))
        ?.sort((a, b) => a.name.localeCompare(b.name)) || [];


    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        if (activeType) {
            getServiceSubTypes({ id: activeType });
        }
    }, [activeType]);

    useEffect(() => {
        if (activeSubTypes?.length > 1) {
            return;
        }

        const slugSubType = pathSubtype?.replace(/^subtype-/gi, "") || "";
        const selectedSubType = serviceSubTypes?.find((item) => item.slug === slugSubType);

        if (selectedSubType?.id) {
            updateOrderField<typeof SERVICE_SUB_TYPE_IDS>(SERVICE_SUB_TYPE_IDS, [selectedSubType.id]);
            updateOrderField<typeof SERVICE_SUB_TYPE_SLUG>(SERVICE_SUB_TYPE_SLUG, `subtype-${selectedSubType.slug}`);
            updateOrderField<typeof SERVICE_SUB_TYPE_NAME>(SERVICE_SUB_TYPE_NAME, selectedSubType?.name || null);
        }
    }, [serviceSubTypes, pathSubtype]);

    const updateOrderField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
        updateField({
            name,
            value,
        });
    };

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    const handleSubtypeClick = (item: any) => {
        updateOrderField<typeof SERVICE_SUB_TYPE_IDS>(SERVICE_SUB_TYPE_IDS, [item.id]);
        updateOrderField<typeof SERVICE_SUB_TYPE_SLUG>(SERVICE_SUB_TYPE_SLUG, `subtype-${item.slug}`);
        updateOrderField<typeof SERVICE_SUB_TYPE_NAME>(SERVICE_SUB_TYPE_NAME, item.name);

        navigate(`/${currentLanguage}/create-order/${category}/${pathType}/subtype-${item.slug}/`);
    };

    const toggleShowAll = () => {
        setShowAll((prevShowAll) => !prevShowAll);
    };

    return (
        <>
            {currentSubtypes && currentSubtypes.length > 0 && (
                currentSubtypes?.map((item, index) => (
                    <div className="form__row" key={index}>
                        <div className="link" style={{ pointerEvents: "none", cursor: "default" }}>
                            <Link
                                to={`/${currentLanguage}/create-order/${category}/${pathType}/subtype-${item.slug}/`}
                                style={{ fontWeight: "bold" }}
                                onClick={(event) => event.preventDefault()}
                            >
                                {item.name}
                            </Link>
                        </div>
                    </div>
                ))
            )}

            {(showAll ? otherSubTypes : otherSubTypes?.slice(0, 6))?.map((item, index) => (
                <div className="form__row" key={index}>
                    <div className="link">
                        <Link
                            to={`/${currentLanguage}/create-order/${category}/${pathType}/subtype-${item.slug}/`}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubtypeClick(item);
                            }}
                        >
                            {item.name}
                        </Link>
                    </div>
                </div>
            ))}

            {otherSubTypes && (
                <div className="form__row">
                    <div className="link">
                        {!showAll && otherSubTypes.length > 6 && (
                            <button
                                className="link link--underline"
                                onClick={toggleShowAll}
                                type="button"
                            >
                                { entities?.order_show_all?.value }
                            </button>
                        )}
                        {showAll && (
                            <button
                                className="link link--underline"
                                onClick={toggleShowAll}
                                type="button"
                            >
                                { entities?.order_hide?.value }
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default SubTypeItem;
