import React, {useEffect, useState} from "react";
import {endpoints} from "../../store/directories/directories.api";
import {endpoints as userEndpoints} from "../../store/user/user.api";
import Form from "../../components/Form/Form";
import Breadcrumbs from "../../components/Breadcrumbs";
import {OrderStepsProps} from "./index";
import {useNavigate, useParams} from "react-router-dom";
import {
    CUSTOM_SERVICE_SUB_TYPE,
    CUSTOM_SERVICE_TYPE,
    FILTER_PAGES,
    SERVICE_CATEGORY_ID,
    SERVICE_CATEGORY_NAME,
    SERVICE_CATEGORY_SLUG,
    SERVICE_CATEGORY_PROMO_TEXT,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_TYPE_ID,
    SERVICE_TYPE_NAME,
    SERVICE_TYPE_SLUG,
    SPECIALIST_IDS,
    IS_PRIVATE,
} from "../../store/order/constant";
import {useActions} from "../../hooks/actions";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import {
    clearCreateOrderState,
    createOrderState,
} from "../../store/order/createOrder.slice";
import {TypesPayload} from "../../models/directoryModel";
import Advertising from "../../components/Advertising";
import CancelOrderButton from "./Specialists/CancelOrderButton";
import {scrollTop} from "../../untils";
import cn from "classnames";

interface Props extends OrderStepsProps {
    setHasFilters: (value: boolean) => void;
    currentLanguage: string;
    isIndividualOrder: boolean;
}

const SelectServiceTypes: React.FC<Props> = ({
                                                 currentLanguage,
                                                 entities,
                                                 onSubmit,
                                                 error,
                                                 profile,
                                                 onSuccess,
                                                 isSuccess,
                                                 setHasFilters,
                                                 isIndividualOrder,
                                             }) => {
    const {category, type: pathType} = useParams();
    const isPrivate = getItemCreateOrder(IS_PRIVATE) || false;
    const [nextStep, setNextStep] = useState<string>(
        !pathType && isPrivate
            ? `${category}/list-of-specialists/`
            : `${category}/${pathType || 'type'}/`
    );
    const navigate = useNavigate();
    const {updateCreateOrderField} = useActions();
    const activeCategory = getItemCreateOrder(SERVICE_CATEGORY_ID);
    const type = getItemCreateOrder(SERVICE_TYPE_ID);
    const custom = getItemCreateOrder(CUSTOM_SERVICE_TYPE);
    const categoryName = getItemCreateOrder(SERVICE_CATEGORY_NAME);
    const activeType = type || custom;
    const {data: serviceCategories} = endpoints.getServiceCategories.useQuery();
    const [getServiceTypes, {data: serviceTypes}] =
        endpoints.getServiceTypes.useLazyQuery();
    const specialistIds = getItemCreateOrder(SPECIALIST_IDS);
    const [getSpecialist, {data: specialist}] =
        userEndpoints.getSpecialistDetail.useLazyQuery();
    const [serviceCategoryPromoText, setServiceCategoryPromoText] = useState('');

    useEffect(() => {
        localStorage.setItem('page', 1);
        if (specialistIds?.length && isIndividualOrder) {
            getSpecialist({id: specialistIds[0]});
        }
    }, [specialistIds]);

    useEffect(() => {
        if (!serviceCategories?.length) return;

        const slugCat = category?.replace(/^category-/gi, '') || '';
        const selectedCat = serviceCategories?.find(item => item.slug === slugCat);

        if (selectedCat?.id) {
            setServiceCategoryPromoText(selectedCat?.promo_text);
            updateOrderField<typeof SERVICE_CATEGORY_ID>(SERVICE_CATEGORY_ID, selectedCat.id);
            updateOrderField<typeof SERVICE_CATEGORY_SLUG>(SERVICE_CATEGORY_SLUG, selectedCat.slug ? `category-${selectedCat.slug}` : 'category');
            updateOrderField<typeof SERVICE_CATEGORY_PROMO_TEXT>(SERVICE_CATEGORY_PROMO_TEXT, selectedCat?.promo_text);
        }

        updateOrderField<typeof SERVICE_CATEGORY_NAME>(SERVICE_CATEGORY_NAME, selectedCat?.name || null);
    }, [serviceCategories])

    useEffect(() => {
        if (!serviceTypes?.length) return;

        const slugType = pathType?.replace(/^type-/gi, '') || '';
        const selectedType = serviceTypes?.find(item => item.slug === slugType || item.id === activeType);

        if (selectedType?.id) {
            updateOrderField<typeof SERVICE_TYPE_ID>(SERVICE_TYPE_ID, selectedType.id);
            updateOrderField<typeof SERVICE_TYPE_SLUG>(SERVICE_TYPE_SLUG, selectedType.slug ? `type-${selectedType.slug}` : 'type');

            setNextStep(`${category}/type-${selectedType.slug}`);
            return;
        }

        updateOrderField<typeof SERVICE_TYPE_NAME>(SERVICE_TYPE_NAME, selectedType?.name || null);

        if (isPrivate) {
            setNextStep(`${category}/list-of-specialists/`);
        } else {
            setNextStep(`meeting-point`);
        }
    }, [serviceTypes])

    useEffect(() => {
        if (activeCategory) {
            getServiceTypes({
                id: activeCategory,
            });
        }
    }, [activeCategory]);


    const currentType =
        serviceTypes?.find((elem: TypesPayload) => elem.id == activeType)?.name || null

    const updateOrderField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
        updateField({
            name,
            value,
        });
    }

    const handleClick = (id: number, nameType: string, slug: string) => {
        [
            SERVICE_TYPE_ID,
            CUSTOM_SERVICE_TYPE,
            CUSTOM_SERVICE_SUB_TYPE,
            SERVICE_SUB_TYPE_IDS,
            FILTER_PAGES,
        ].forEach((key) =>
            updateField({
                name: key as keyof createOrderState,
                value: clearCreateOrderState[key as keyof createOrderState],
            })
        );

        // Todo: Вызывает ошибку
        // if (activeType === id) {
        //     setHasFilters(false);
        // }

        updateOrderField<typeof SERVICE_TYPE_ID>(SERVICE_TYPE_ID, activeType === id ? null : id);
        updateOrderField<typeof SERVICE_TYPE_NAME>(SERVICE_TYPE_NAME, nameType || null);
        updateOrderField<typeof SERVICE_TYPE_SLUG>(SERVICE_TYPE_SLUG, slug ? `type-${slug}` : null);

        setNextStep(`${category}/type-${slug}/`);

    };

    const updateField = <K extends keyof createOrderState>({
                                                               name,
                                                               value,
                                                           }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const _onSuccess = () => {
        if (isSuccess) {
            onSuccess(nextStep);
        }
    };

    const back = () => {
        navigate(`/${currentLanguage}/create-order/`);
        scrollTop();
    };

    const crumbs = [
        {
            active: true,
            name: categoryName || entities?.service_task?.value
        }
    ]

    return (
        <>
            <h1 className="visually-hidden">
                {entities?.order_select_one_category?.value}
            </h1>
            <div className="inner-container">
                <div className="new-order__wrapper">
                    <Breadcrumbs crumbs={crumbs}/>
                    <div className="new-order__header">
                        <h2 className="title title--f46">
                            {entities?.select_services_direction_title?.value?.replace(/:service/g, categoryName)}
                        </h2>
                        <CancelOrderButton le={profile} entities={entities}/>
                        <p className="subtitle">
                            {serviceCategoryPromoText ? serviceCategoryPromoText : entities?.order_little_more_details?.value}
                        </p>
                        <p className="subtitle">
                            {entities?.service_selection_prompt_text?.value}
                        </p>
                    </div>
                    <div className="new-order__form form">
                        <Form
                            isSuccess={isSuccess}
                            onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
                                onSubmit(e, nextStep);
                                scrollTop();
                            }}
                            entities={entities}
                        >
                            {(invalidFields, error) => {
                                return (
                                    <>

                                        <div className="new-order-start">
                                            <div className="form__row">
                                                {serviceTypes?.map(
                                                    (
                                                        elem: TypesPayload,
                                                    ) => (
                                                        <button
                                                            type={"submit"}
                                                            key={elem.id}
                                                            className={cn("new-order-start__toggle", elem.id === activeType && 'is-active')}
                                                            onClick={(event) => {
                                                                handleClick(elem?.id, elem?.name, elem?.slug);
                                                            }}
                                                        >{elem?.name}</button>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="form__row form__controls">
                                            <CancelOrderButton
                                                profile={profile}
                                                entities={entities}
                                            />
                                            <button
                                                className="btn form__back-link btn--transparent"
                                                type="button"
                                                onClick={back}
                                            >{entities?.common_back_button?.value}</button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
                <ul className="advertising-list create-order">
                    <Advertising countCards={5} entities={entities} isHorizontal={true}/>
                </ul>
            </div>
        </>
    );
};

export default SelectServiceTypes;
