import React, {useEffect, useState} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import Advertising from "../../../components/Advertising";
import SpecialistPanel from "../../../components/SpecialistPanel";
import LanguagesFilter from "./LanguagesFilter";
import {endpoints} from "../../../store/order/order.api";
import TypeItem from "./TypeItem";
import SubTypeItem from "./SubTypeItem";
import MeetingPoint from "./MeetingPointFilter";
import {formatQueryString, getPages, scrollTop} from "../../../untils";
import {
    getItemCreateOrder,
    getItemsCreateOrder,
    getItemsEditOrder,
    keysFilter,
    updateItemCreateOrder,
} from "../../../store/order/selectors";
import Filters from "./Filters";
import Icon from "../../../components/Icon";
import List from "./List";
import {OrderStepsProps} from "../index";
import {createOrderState} from "../../../store/order/createOrder.slice";
import {editOrderState} from "../../../store/order/editOrder.slice";
import MobileFilters from "./MobileFilters";
import classNames from "classnames";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {ChangeStepType} from "../../../pages/Registration/SpecialistNewService/SpecialistNewService";
// import {useHistoryBackHandler} from "../../../hooks/useHistoryBackHandler";
import {
    SERVICE_CATEGORY_NAME,
    SERVICE_CATEGORY_SLUG,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_TYPE_NAME,
    SERVICE_TYPE_SLUG,
} from "../../../store/order/constant";
import { useGetUrlCategories } from "../../../hooks/useGetUrlCategories";
import { useGetUrlSubtypes } from "../../../hooks/useGetUrlSubtypes";
import { useActions } from "../../../hooks/actions";

interface Props extends OrderStepsProps {
    clearSpecialistIds: () => void;
    specialistIds: number[];
    changeSpecialistIds: (value: number, name?: string) => void;
    isEdit?: boolean;
    updateOrder?: (value?: string, id?: number) => void;
    changeIsAllowSelectSpec?: (value: boolean) => void
    isPrivate: boolean;
    changeStep?: ChangeStepType;
}

const Specialists: React.FC<Props> = ({
    entities,
    currentLanguage,
    onSubmit,
    onSuccess,
    isSuccess,
    clearSpecialistIds,
    specialistIds,
    changeSpecialistIds,
    updateOrder,
    isEdit = false,
    isPrivate,
    changeStep,
    profile,
    changeIsAllowSelectSpec
}) => {
    const { updateCreateOrderField } = useActions();
    const { category, type: pathType, subtype: pathSubtype } = useParams();
    const navigate = useNavigate();
    const [_getSpecialist, { data: specialists }] =
        endpoints.getOrderSpecialists.useLazyQuery();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pages, setPages] = useState<number[]>([]);
    const [activeMobileFilter, setActiveMobileFilter] = useState<string>("");
    const bodyOrder = isEdit ? getItemsEditOrder() : getItemsCreateOrder();
    const title = entities?.order_select_specialists_title?.value;
    const description = entities?.order_select_specialists_text?.value;
    const serviceSubTypesIdsLength = getItemCreateOrder(SERVICE_SUB_TYPE_IDS)?.length;
    const isAllowSelectSpec = getItemCreateOrder("isAllowSelectSpecialist");
    const categoryName = getItemCreateOrder(SERVICE_CATEGORY_NAME);
    const categoryPath = getItemCreateOrder(SERVICE_CATEGORY_SLUG);
    const typePath = getItemCreateOrder(SERVICE_TYPE_SLUG);
    const typeName = getItemCreateOrder(SERVICE_TYPE_NAME);
    const subtypePath = getItemCreateOrder(SERVICE_SUB_TYPE_SLUG);
    const subtypeName = getItemCreateOrder(SERVICE_SUB_TYPE_NAME);
    const subtypeIds = getItemCreateOrder(SERVICE_SUB_TYPE_IDS) || [];
    const validActiveSubTypes = Array.isArray(subtypeIds) ? subtypeIds : [];
    let activeSubTypes = [];

    if (!isEdit) {
        useGetUrlCategories(currentLanguage, profile);
        const serviceSubTypes = useGetUrlSubtypes();
        activeSubTypes = serviceSubTypes?.filter((item) => validActiveSubTypes.includes(item.id)) || [];
    }

    const changeCurrentPage = (value: number) => {
        setCurrentPage(value);
        scrollTop();

        localStorage.setItem('page', value.toString())
    };

    useEffect(() => {
        getSpecialist();
    }, []);

    useEffect(() => {
        getSpecialist();
    }, [currentPage, bodyOrder]);

    const getSpecialist = () => {
        const savedPageJson = localStorage.getItem('page') || ''
        const savedPage = +savedPageJson

        if (savedPage) {
            setCurrentPage(savedPage)
        }

        let data: editOrderState = {
            page: savedPage || currentPage,
        };
        keysFilter.forEach(
            (elem: keyof editOrderState | keyof createOrderState) => {
                if (
                    bodyOrder[
                        elem as keyof editOrderState | keyof createOrderState
                    ]
                ) {
                    data = {
                        ...data,
                        [elem]: bodyOrder[elem],
                    };
                }
            }
        );

        let isActiveButton = !!(data.is_my_address || data.is_remote || data.is_specialist_address) && !!data?.language_ids?.length;

        if (data.filter_pages?.length) {
            const selectedFilterCount = data.filter_pages?.filter((item) => !!item.service_type_filter_value_ids?.length)?.length || 0;
            isActiveButton = isActiveButton && selectedFilterCount === data.filter_pages?.length;
        }

        changeIsAllowSelectSpec?.(isActiveButton);

        if (data?.specialist_ids) {
            delete data.specialist_ids;
        }
        _getSpecialist({
            params: formatQueryString(data),
        });
    };

    useEffect(() => {
        if (specialists) {
            const count = Math.ceil(specialists.total / 10);
            if (count > 1) {
                const pages = getPages(count);
                setPages(pages);
            } else {
                setPages([]);
            }
        }
    }, [specialists]);

    const _onSubmit = (e?: React.FormEvent<HTMLInputElement>) => {
        return onSubmit(e, "when-to-start");
    };

    const _onSuccess = () => {
        if (onSuccess) {
            onSuccess("when-to-start");
        }
    };

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const handleClearActiveMobileFilter = () => setActiveMobileFilter("");

    const crumbs = [
        {
            name: categoryName || entities?.service_task?.value,
            link: `create-order/${categoryPath}`
        },
        ...(!typePath ? [] : [
            {
                name: typeName || entities?.type_of_service_2?.value,
                link: `create-order/${categoryPath}/${typePath}`
            }
        ]),
        {
            active: true,
            name: subtypeName
                ? `${subtypeName} - ${entities?.choosing_a_specialist?.value}`
                : serviceSubTypesIdsLength
                    ? `${entities?.subtype_of_service_2?.value} (${serviceSubTypesIdsLength}) - ${entities?.choosing_a_specialist?.value}`
                    : entities?.choosing_a_specialist?.value
        }
    ];

    const back = () => {
        setCurrentPage(1);
        localStorage.setItem('page', 1);
        navigate(!typePath
            ? `/${currentLanguage}/create-order/${categoryPath}/${typePath || 'type'}`
            : `/${currentLanguage}/create-order/${categoryPath}/${typePath || 'type'}/${subtypePath || 'subtype'}`);
        scrollTop();
    };

    // useHistoryBackHandler(() => changeStep?.("category"));

    const updateField = <K extends keyof createOrderState>({
       name,
       value,
   }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    const updateOrderField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
        updateField({
            name,
            value,
        });
    };

    const handleSubtypeClick = (item: any) => {
        updateOrderField<typeof SERVICE_SUB_TYPE_IDS>(SERVICE_SUB_TYPE_IDS, [item.id]);
        updateOrderField<typeof SERVICE_SUB_TYPE_SLUG>(SERVICE_SUB_TYPE_SLUG, `subtype-${item.slug}`);
        updateOrderField<typeof SERVICE_SUB_TYPE_NAME>(SERVICE_SUB_TYPE_NAME, item.name);

        navigate(`/${currentLanguage}/create-order/${category}/${pathType}/subtype-${item.slug}/`);
    };

    return (
        <section className="find-pro">
            <div className="inner-container">
                <Breadcrumbs crumbs={crumbs}/>
            </div>
            <div className="inner-container">
                <div className="find-pro__container">
                    <div className="find-pro__wrapper">
                        <div className="find-pro__title-wrap">
                            <h1 className="title title--f46">{subtypeName || typeName || categoryName || title}</h1>
                            <span className="keywords">
                                { activeSubTypes.length > 1
                                    ? (
                                        activeSubTypes.reduce((acc, subType, index) => {
                                            if (index > 0) {
                                                acc.push(' | ');
                                            }
                                            acc.push(
                                                <Link
                                                    key={subType.slug}
                                                    to={`/${currentLanguage}/create-order/${categoryPath}/${typePath}/subtype-${subType.slug}/`}
                                                    className="link"
                                                    onClick={() => handleSubtypeClick(subType)}
                                                >
                                                    {subType.name}
                                                </Link>
                                            );
                                            return acc;
                                        }, [])
                                    )
                                    : (activeSubTypes[0]?.keywords
                                            ?.split(',')
                                            .map(keyword => keyword.trim())
                                            .map(keyword => keyword.charAt(0).toUpperCase() + keyword.slice(1))
                                            .slice(0, 5)
                                            .join(' | ')
                                        || '')}
                            </span>
                            <p className="find-pro__text">{description}</p>
                        </div>
                        <section className="filter form form--filter">
                            <form>
                                <MobileFilters
                                    isEdit={isEdit}
                                    entities={entities}
                                    getSpecialist={getSpecialist}
                                    changeActiveMobileFilter={
                                        setActiveMobileFilter
                                    }
                                />
                                <div className="filter__block">
                                    {/*{!typePath && (*/}
                                    {/*    <CategoryItem*/}
                                    {/*        currentLanguage={currentLanguage}*/}
                                    {/*        isEdit={isEdit}*/}
                                    {/*        entities={entities}*/}
                                    {/*    />*/}
                                    {/*)}*/}
                                    {!subtypePath && (
                                        <TypeItem
                                            currentLanguage={currentLanguage}
                                            isEdit={isEdit}
                                            entities={entities}
                                        />
                                    )}
                                    {typePath && subtypePath && (
                                        <SubTypeItem
                                            currentLanguage={currentLanguage}
                                            isEdit={isEdit}
                                            entities={entities}
                                        />
                                    )}
                                </div>
                                <div
                                    className={classNames("filter__block", {
                                        "is-open":
                                            activeMobileFilter ===
                                            "meeting_place",
                                    })}
                                >
                                    <button
                                        className="filter__subtitle"
                                        type="button"
                                        onClick={handleClearActiveMobileFilter}
                                    >
                                        {entities?.common_meeting_place?.value}
                                        <Icon type="arrow-left-pagination" />
                                    </button>
                                    <div className="filter__wrap-block">
                                        <MeetingPoint
                                            entities={entities}
                                            isEdit={isEdit}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={classNames("filter__block", {
                                        "is-open":
                                            activeMobileFilter === "languages",
                                    })}
                                >
                                    <LanguagesFilter
                                        entities={entities}
                                        isEdit={isEdit}
                                        handleClearActiveMobileFilter={
                                            handleClearActiveMobileFilter
                                        }
                                        profile={profile}
                                    />
                                </div>
                                <Filters
                                    entities={entities}
                                    update={getSpecialist}
                                    isEdit={isEdit}
                                    activeMobileFilter={activeMobileFilter}
                                    handleClearActiveMobileFilter={
                                        handleClearActiveMobileFilter
                                    }
                                />
                                <div className={classNames("filter__btns", {
                                    "is-open": activeMobileFilter,
                                })}>
                                    <button
                                        className="btn btn btn--rounded btn--transparent filter__btn filter__btn--cancel"
                                        type="button"
                                        onClick={handleClearActiveMobileFilter}
                                    >
                                        {entities?.common_save_button?.value}
                                    </button>
                                </div>
                                <div className="filter__btns">
                                    <button
                                        className="btn btn btn--rounded btn--transparent filter__btn filter__btn--cancel"
                                        type="button"
                                    >
                                        {entities?.common_cancel_button?.value}
                                    </button>
                                </div>
                            </form>
                        </section>
                        <div className="find-pro__content">
                            <List
                                isPrivate={isPrivate}
                                isEdit={isEdit}
                                specialists={specialists?.items || []}
                                specialistIds={specialistIds || []}
                                pages={pages}
                                changeSpecialistIds={changeSpecialistIds}
                                entities={entities}
                                changeCurrentPage={changeCurrentPage}
                                currentPage={currentPage}
                                isActiveSubmit={isAllowSelectSpec}
                            />
                            {specialists && specialists?.items?.length < 1 && (
                                <div className="content-missing">
                                    <div className="content-missing__wrapper">
                                        <div className="content-missing__img">
                                            <Icon
                                                type="disappointed"
                                                width={32}
                                                height={32}
                                            />
                                        </div>
                                        <p className="content-missing__text">
                                            {
                                                entities
                                                    ?.order_specialists_not_found
                                                    ?.value
                                            }
                                        </p>
                                    </div>
                                </div>
                            )}
                            {/* TODO: Удалены кнопки Назад и Продолжить */}
                            {/*<div className="specialists__buttons">*/}
                            {/*    <div className="form__row form__controls">*/}
                            {/*        <button*/}
                            {/*            className="btn form__back-link btn--transparent"*/}
                            {/*            type="button"*/}
                            {/*            onClick={back}*/}
                            {/*        >*/}
                            {/*            {*/}
                            {/*                entities?.common_back_button*/}
                            {/*                    ?.value*/}
                            {/*            }*/}
                            {/*        </button>*/}
                            {/*        {*/}
                            {/*            isAllowSelectSpec ? <>*/}
                            {/*                <button*/}
                            {/*                    className="btn btn--transparent btn--bg-blue"*/}
                            {/*                    type="submit"*/}
                            {/*                    onClick={_onSubmit}*/}
                            {/*                    disabled={specialistIds.length === 0}*/}
                            {/*                >*/}
                            {/*                    {entities*/}
                            {/*                        ?.common_continue_button*/}
                            {/*                        ?.value || ""}*/}
                            {/*                </button>*/}
                            {/*            </> : <>*/}
                            {/*                <div className="popover popover--grey">*/}
                            {/*                    <button*/}
                            {/*                        disabled*/}
                            {/*                        className="btn btn--transparent btn--bg-blue"*/}
                            {/*                        type="submit"*/}
                            {/*                    >*/}
                            {/*                        {entities*/}
                            {/*                            ?.common_continue_button*/}
                            {/*                            ?.value || ""}*/}
                            {/*                    </button>*/}
                            {/*                    <span className="popover__tip">*/}
                            {/*                        {entities?.order_fill_filters?.value}*/}
                            {/*                    </span>*/}
                            {/*                </div>        */}
                            {/*            </>*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {!isEdit && (
                        <div className="specialists__wrapper no-scrollbar specialists__wrapper--create-order">
                            <Advertising countCards={5} entities={entities} />
                        </div>
                    )}
                </div>
            </div>
            <SpecialistPanel
                onSubmit={_onSubmit}
                specialistIds={specialistIds}
                entities={entities}
                isSuccess={isSuccess}
                onSuccess={_onSuccess}
                isEdit={isEdit}
                isPrivate={isPrivate}
                clearSpecialistIds={clearSpecialistIds}
                updateOrder={updateOrder}
            />
        </section>
    );
};

export default Specialists;
